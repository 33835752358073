import { GetInfo } from '../slices/fetchReducer'

import store from '../store'
import { Iinfodb } from '../types/interface'

const emptyInfo = {
    pk: "",                
    lunchOpenTime: { $H: 0 , $m: 0},
    lunchCloseTime: { $H: 0 , $m: 0},
    lunchDuration: { $H: 0 , $m: 0},
    lunchOpenTimeWeekend: { $H: 0 , $m: 0},
    lunchCloseTimeWeekend: { $H: 0 , $m: 0},
    lunchDurationWeekend: { $H: 0 , $m: 0},
    dinnerOpenTime: { $H: 0 , $m: 0},
    dinnerCloseTime: { $H: 0 , $m: 0},
    dinnerDuration: { $H: 0 , $m: 0},
    dinnerOpenTimeWeekend: { $H: 0 , $m: 0},
    dinnerCloseTimeWeekend: { $H: 0 , $m: 0},
    dinnerDurationWeekend: { $H: 0 , $m: 0},
    MaxNumberOfGuestPerReservation: 0,
    TotalNumberOfTables: 0,
    timeIntervallBetweenEachReservation: 0,
    Reservation_ClosedDates:  [],
    Reservation_Active: false,
   
    Shop_Active: false,
   
}

export const FetchInfoFromStore = async() => {
    try {
        
        interface Ires {
            statusCode: number
            body: {
                data: Iinfodb
            }
        }
            
        let info:Iinfodb = store.getState()?.fetchReducer?.info

     
        if(Object.keys(info).length === 0){
           const result = await store.dispatch(GetInfo({}))
                .unwrap()
                .then((res:Ires) => {            
                    
                    info = res?.body?.data
                    //info = store.getState().fetchReducer.info                                                     
                    return {statusCode: res?.statusCode, data: info}
                   
                })
                .catch((error) => {
                    console.log(error)                                        
                    return {statusCode: 500, data: emptyInfo}
                })
                
            return result
        }
        return {statusCode: 200, data: info}

    } catch (error) {
        console.log("Info error",error)              
        return {statusCode: 500, data: emptyInfo}
    }

}