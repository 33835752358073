import { useState, useEffect, useRef, useLayoutEffect} from 'react'
import { useLocation } from 'react-router-dom'
import gsap from 'gsap'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'

import FacebookIcon from '@mui/icons-material/Facebook';

import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

//! NOT WORKING WITH SWIPER v 10  import { Pagination ,EffectFade,Autoplay, Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';


// self
import { Iinfo, IeditInfo } from '../../types/interface'






//* MAIN  */    
interface propsinit {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
    
  
}
const EventPage = (props:propsinit) => {
    const { editInfo } = props

    const location = useLocation();
   
   
   
   
    const header_ref:any = useRef(null)
    

    //* Scroll to top at rendering  */
    useEffect(() => {window.scrollTo(0, 0)},[])




    //* Image Dialog for viewing fullscreen image */    
    interface FullScreebDialogProps {
        open: boolean
        image: string
    }
    const [openImageDialog, setOpenImageDialog] = useState(false)
    const [selectedImage, setSetselectedImage] = useState("")
    const FullScreebDialog = (props: FullScreebDialogProps) => {
        const { image, open } = props;
      
        const handleClose = () => {    
            setOpenImageDialog(false)
        };
                
        return (
        <Dialog onClose={handleClose} open={open}  fullWidth maxWidth={"xl"}> 
                <img
                    onClick={handleClose}
                    src={`https://${process.env.REACT_APP_CDN_URL}/${image}?w=164&h=164&fit=crop&auto=format`}                    
                    style={{width: "100%", height: "100%"}}
                    onError={(event)=> process.env.REACT_APP_DEFAULTIMAGE ? event.currentTarget.src = process.env.REACT_APP_DEFAULTIMAGE : ""}
                    //src={`${image}?w=164&h=164&fit=crop&auto=format`}
                    //srcSet={`https://${process.env.REACT_APP_CDN_URL}/${selectedImage}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={image}
                    loading="lazy"
                />
            </Dialog>
        );
    }




    //* Resize chart when width changes*/
    /* For Some Reason If a have 2 or more images shit is not fucking straight, so this is nessecary... */
    const [overflow, setOverflow] = useState<any>("")
    const handleResize = () => {
        let width =  header_ref.current ? header_ref?.current?.offsetWidth : 0
        setOverflow(width)
        if(width < 600){
          
        }else{
          
        }
    }
    useEffect(() => {              
        window.addEventListener("resize", handleResize, false);
    }, [])
    useLayoutEffect(() => {
       
        
        const ctx = gsap.context(()=>{ 
            if(location?.state?.images?.length === 1){

                gsap.set('.swiper-slide',{
                    width: "100%",
                    color:"purple"
                })
            }                            
        },header_ref)
    
        return () => {
        ctx.revert()
        };
    }, [overflow])

    return (
        <Container className="flex__center-c" maxWidth={false} ref={header_ref}  sx={{minHeight:"100vh", position:"relative"/* , bgcolor:"primary.dark" */,padding:"2rem 0"}} >

            {/* Title */}
            <Typography component="h1" variant='h2' color="primary" paragraph marginTop={"15rem"} maxWidth={"90vw"}> 
                {location?.state?.title}
            </Typography>


            {/* Info */}
            <Typography component="h1" variant='h5' /* color="primary.light" */ paragraph maxWidth={"90vw"}>
                {location?.state?.info}
            </Typography>


            <Box className="flex" sx={{gap:"2rem",margin:"2rem 0"}} >
                <IconButton sx={{width:"20px",height:"20px", backgroundColor:"#ffffff00"}} href={ editInfo?.Footer?.Instagram_Link ?  editInfo?.Footer?.Instagram_Link : "" } target="_blank" rel="noopener">
                    <InstagramIcon fontSize='large' color='primary' />
                </IconButton>

                <IconButton sx={{width:"20px",height:"20px", backgroundColor:"#ffffff00"}} href={editInfo?.Footer?.Facebook_Link ? editInfo?.Footer?.Facebook_Link : ""} target="_blank" rel="noopener">
                    <FacebookIcon fontSize='large' color='primary' />
                </IconButton>

            </Box>


            <Swiper 
                className='flex__center-r'              
                //! NOT WORKING WITH SWIPER v 10  modules={[Autoplay,EffectFade,Pagination,Navigation]} 
                //! NOT WORKING WITH SWIPER v 10  effect={"fade"}          
                //! NOT WORKING WITH SWIPER v 10  pagination
                //! NOT WORKING WITH SWIPER v 10  navigation
                //! NOT WORKING WITH SWIPER v 10  autoplay={{ delay: 5000, disableOnInteraction: true }}
                //! NOT WORKING WITH SWIPER v 10  loop={true}
                //! NOT WORKING WITH SWIPER v 10 spaceBetween={30}                   
            >        
            {location?.state?.images?.map((img:string,i:number) => {        
                return (
                    <SwiperSlide key={i} style={{width:"fit-content !important"}}>
                        <img
                    
                            className='swiper-img'
                            src={`https://${process.env.REACT_APP_CDN_URL}/${img}`}                        
                            //src={`${img}`}                        
                            //srcSet={`https://${process.env.REACT_APP_CDN_URL}/${img}`}
                            onError={(event)=> process.env.REACT_APP_DEFAULTIMAGE ? event.currentTarget.src = process.env.REACT_APP_DEFAULTIMAGE : ""}
                            onClick={()=>{setOpenImageDialog(true); setSetselectedImage(img) }} 
                            alt='swipercar'
                            loading="lazy"
                        />
                    </SwiperSlide>
                    )
            })}
            <FullScreebDialog open={openImageDialog} image={selectedImage}/>
            </Swiper>    
            

        </Container>
    )
}

export default EventPage