import { useState, useRef, Fragment} from 'react'
import { useNavigate } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SaveIcon from '@mui/icons-material/Save';

import { ReactComponent as SwishIcon } from "../../assets/swish.svg";
import { ReactComponent as KlarnaIcon } from "../../assets/klarna.svg";


//* REDUX */
import  { AppDispatch }  from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentReqSwish, GetSwishStatus, PaymentReqStripe } from '../../slices/shopReducer';

// self

import { Review, Logo, SimpleBackdropLoading } from '../../components/index';
import { Iinfo } from '../../types/interface';
import { theme } from '../../App';


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
  





const steps = ['Information', 'Review your order'];

interface Iprops {
    info: Iinfo
}
const Checkout = (props:Iprops) => {
    const { info } = props;

	const dispatch:AppDispatch = useDispatch()

    const navigate = useNavigate()

    const selectorCart = useSelector((state: any) => state.cartReducer)
    
   
   
    const header_ref:any = useRef(null)
    
    const [activeStep, setActiveStep] = useState(0);
    const [qrCode, setQrCode] = useState<any>();
    const [message, setMessage] = useState("")
    const [radioButtonText, setRadioButtonText] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const [paymentMethod, setPaymentMethod] = useState('');
    const formDataInit = {
        firstName:"",
        lastName:"",
        phone:"",
        //address1:"kallegatan 5",
        //address2:"",
        //city:"Ankeborg",
        //state:"ankeborgs region",
        //postalCode:"51290",
        //country:"sverige",
        email:"",
        approveNewsletter: false,
        store_data: false,
        
    }
    const [formData, setFormData] = useState(formDataInit);
    const [expand, setExpand] = useState(false)
    const [purchaseLoading, setPurchaseLoading] = useState(false)

   

    
    
    const mobileStepper = useMediaQuery('(max-width:690px)');


    

    //* SUBMIT
    const Submit = async(event: React.FormEvent<HTMLFormElement>) => {
        setPurchaseLoading(true)
        event.preventDefault();
        const serverAddress = process.env.NODE_ENV === "production" ? `https://api.${process.env.REACT_APP_COMPONENT}.se` : "http://localhost:5000"
        const dataToSend = {
            customerItems: selectorCart?.cart,
            email: formData?.email, 
            approveNewsletter: formData?.approveNewsletter,
            firstName:formData?.firstName,
            lastName:formData?.lastName,
            phone:formData?.phone,
            //address1:formData?.address1,
            //address2:formData?.address2,
            //city:formData?.city,
            //state:formData?.state,
            //postalCode:formData?.postalCode,
            //country:formData?.country, 
            paymentMethod: paymentMethod,
            store_data: formData?.store_data,

        }
        
        if(selectorCart?.cart?.length <= 0){
            return
        }


        await dispatch(PaymentReqStripe(dataToSend))
            .unwrap()
            .then((res) => {         
                setPurchaseLoading(false)
                if(res?.statusCode === 200){          
                    localStorage.clear()
                    window.location.replace(res?.body?.url);                   
                }else if(res?.body?.message){
                    setMessage(res?.body?.message)
                }else{
                    setMessage("Något gick fel, försök igen")
                }
                setLoading(false)
                    
                }) 
        
                return
      

     
         
                        
    }
    const handleNext = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const regexstring = /^\+?46[0-9]+$/g
        switch (activeStep) {
            case 0:                               
                if(formData?.phone?.search(regexstring) !== -1 ) {
                    setActiveStep(activeStep + 1);            
                    setMessage("")
                }else{
                    setMessage("Phone number must start with 46.. / +46..")
                }
                break;
           /*  case 1:
              
            //if more payment methods exist
                if(paymentMethod !== ""){
                    setActiveStep(activeStep + 1);        
                    setError(false)
                    setRadioButtonText("")
                }else{
                    setError(true)                            
                    setRadioButtonText("Please select a payment method")
                }                   
                setMessage("")
                break; */
        
            default:
                break;
        }
        //setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const handlePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod((event.target as HTMLInputElement).value);
    };


    



    interface Ishopitem {
        id:string,quantity:number,price:number,title:string
    }
   

    const ShopItemsComponent = () => {
        return(
            <Box className="flex__center-c"  width={!mobileStepper ? "40vw" : "100vw"}  >
                    <Box className="flex__center-c"  width={!mobileStepper ? "35vw" : "90vw"}  sx={{justifyContent:"space-between",marginTop:"10rem"}}> 

                        {/* items */}
                        <Box sx={{maxHeight:"50vh", overflowY:"auto",width:"100%",p:"1rem 0"}}>
                            {selectorCart?.cart?.map((shopitem:Ishopitem,i:number) => {
                               
                          
                                return(
                                    <Box key={`cart-shopitem${i}`} marginBottom="1rem" className="flex__center-r"  sx={{justifyContent:"space-between"}}>

                            

                                        

                                        {/* title and size */}
                                        <Box marginLeft="0.5rem" className="flex__center-r " >
                                            <Typography variant='subtitle1' className="" sx={{maxWidth:"20vw",overflowX:"hidden",textOverflow: "ellipsis"}}>
                                                {shopitem?.title}
                                            </Typography>          
                                            <Divider  orientation="vertical" flexItem sx={{m:"0 0.5rem"}}/>
                                            {/* Image with badge */}
                                            <Typography variant='subtitle1' className="" sx={{maxWidth:"20vw",overflowX:"hidden",textOverflow: "ellipsis"}}>
                                                x {shopitem?.quantity}
                                            </Typography>                                            
                                            
                                        </Box>

                                        


                                        {/* price */}
                                        <Box marginLeft="0.5rem" className="flex__center-c " alignItems="flex-start" sx={{marginRight:"0.5rem"}} >                                            
                                            <Typography variant='subtitle1' >
                                                {(shopitem?.price * shopitem?.quantity).toFixed(2)} kr
                                            </Typography>
                                        </Box>

                                


                                    </Box>
                                )
                            })}

                        </Box>

                        {/* Total price */}
                        <Box className="flex" width="100%" flexDirection="column" marginTop="10rem">
                                                        
                            <Box className="flex" justifyContent="space-between" width="100%" margin="1rem 0.5rem 0">
                                <Typography variant='h5' sx={{fontWeight:100}}>
                                    {info?.Checkout?.Text_Total}
                                </Typography>
                                <Box className="flex__center-r" /* justifyContent="space-between" width="100%" margin="1rem 0.5rem 0"  */>
                                    <Typography variant='subtitle1' sx={{alignSelf:"flex-end"}}>
                                        {info?.Checkout?.Text_Sek}
                                    </Typography>
                                    <Typography variant='h5'>
                                    &nbsp; {selectorCart?.totalprice?.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Box>
                            
                        </Box>

                    </Box>
                </Box>
        )
    }


    //* Choose Payment method through radio buttons stripe or swish  Component 
    const PaymentMethod = () => {

        return(
            <Box>

                <FormControl error={error}>
                    {/*  <FormLabel color='info'>{props?.Cart?.PaymentMethod_Label_Option1}</FormLabel> */}
                    <RadioGroup                        
                        name="paymentmethod"                   
                        value={paymentMethod}
                        onChange={handlePaymentMethod}
                        color='primary'
                    
                    >
                        {/* <Box className="flex" sx={{alignItems:"center"}}>
                            <FormControlLabel value="qr" control={<Radio />} label={info?.Checkout?.PaymentMethod_Label_Option1} />
                            

                            <QrCode2Icon sx={{width:"3vmax",height:"3vmax",color:"text.primary"}} />
                        </Box> */}
                        
                        <Box className="flex">
                            <FormControlLabel value="phone" control={<Radio />}  label={info?.Checkout?.PaymentMethod_Label_Option2} color='primary' />
                            <PhoneAndroidIcon sx={{width:"3vmax",height:"3vmax",color:"text.primary"}} />
                            
                            {/* <SwishDarkBgIcon  width="5vmax" height="5vmax"/>                 */}
                        </Box>
                    </RadioGroup>
                    <FormHelperText  sx={{fontSize:"1.2vmax"}}>{radioButtonText}</FormHelperText>
                </FormControl>
         
          </Box>
        )
    }
    //* Address Form
    const AddressForm = (setFormData:(prev:any)=>void ) => {   

        const handleFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target
            setFormData((prev:any) => ({...prev, [name]:value}))
        }

        return (
            <Fragment>
            {/*   <Typography variant="h6" gutterBottom>
                Shipping address
              </Typography> */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"                    
                    label={info?.Checkout?.FirstName_TextField}                 
                    sx={{label: {color:"grey.400"}}}       
                    value={formData?.firstName}
                    onChange={handleFormData}
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"                    
                    label={info?.Checkout?.LastName_TextField}
                    sx={{label: {color:"grey.400"}}}
                    value={formData?.lastName}
                    onChange={handleFormData}
                    fullWidth
                    autoComplete="family-name"
                    variant="standard"
                  />
                </Grid>                               
                <Grid item xs={12}>
                  <TextField                  
                    required  
                    id="cart-phone"
                    name="phone"
                    fullWidth
                    autoComplete="shipping phone"
                    variant="standard"
                    sx={{label: {color:"grey.400"}}}
                    label={info?.Checkout?.Phone_TextField}                        
                    value={formData?.phone}
                    onChange={handleFormData} 
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    required
                    id="cart-email"
                    name="email"
                    //label="Email Address"
                    fullWidth
                    autoComplete="shipping email"
                    variant="standard"
                    sx={{label: {color:"grey.400"}}}
                    label={info?.Checkout?.Email_TextField}                        
                    value={formData?.email}
                    onChange={handleFormData} 
                  />
                </Grid>             
              </Grid>
            </Fragment>
        );
    }
    const SwishPaymentQr = () => (
        <Dialog 
            open 
            fullScreen
            
            sx={{width:"100vw"}}
            >            
            <LinearProgress color='success'/>
            <Box width="100vw" height="100vh" className="flex__center-c" sx={{overflowX: 'hidden'}}>
            
                <Box>
                    <Logo width={10} height={10} fill={theme.palette.primary.main} />
                </Box>
            
                <Typography variant='h3' sx={{mb:"1rem",color:"primary.main"}}>
                    {info?.Checkout?.ScanQr}
                </Typography>
                <img src={qrCode} style={{width:"20vmin",height:"20vmin"}} alt="qrswish"/> 
                {/* <img src={`images/qr_test.png`} style={{width:"20vmin",height:"20vmin"}} alt="qrswish"/>  */}
            </Box>
        </Dialog>
    )
    //* Renders the different steps first Information form => payment method => review.
    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return AddressForm(setFormData)
            /* case 1:
                return PaymentMethod() */
            case 1:
                return Review(formData, selectorCart, paymentMethod,props,setFormData)
            /* case 2:
                return SwishPaymentQr() */
            default:
                navigate("/successpayment/?success=false",{state:{message:"Unexpexted Error" } })
        }
    }

 
    
 
    return (
        <Container component="section" className="flex__center-r flex__r-c" maxWidth={false} ref={header_ref}  sx={{padding:"0 !important",margin:"0 !important",minHeight:"100vh", position:"relative", bgcolor:"",color:"",justifyContent:"space-between" }} >

            {mobileStepper && 
            <Box sx={{marginTop:"5rem"}}>
                <Accordion expanded={expand} onChange={()=>setExpand(!expand)} >
                    <AccordionSummary className="flex__center-r"  sx={{bgcolor:"",justifyContent:"space-between","& .MuiAccordionSummary-content":{display:"flex",justifyContent:"space-around"}}}>
                        <Typography>{info?.Checkout?.Accordion_OpenItems}</Typography>
                        <Typography>{selectorCart?.totalprice?.toFixed(2)} kr</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{bgcolor:""}}>
                        <ShopItemsComponent />
                    </AccordionDetails>
                </Accordion>
            </Box>
            }
            
            {/* Payment Options */}
            <Box className="flex__center-c" width={!mobileStepper ? "60vw" : "100vw"} sx={{/* alignItems:"flex-start", width:`60vw` */minHeight:"100vh",position:"relative"}} >

                {/* Bg image */}
                <img src={`${info?.Checkout?.BgImage}`} style={{zIndex:0,width:"100%"}} className="header-img-noblur" alt='checkoutbg'/>


                {/* Main information => information => paymentmethod => finish purchaes */}
                <Paper className="flex__center-c" sx={{bgcolor:"",zIndex:2, width:"80%", padding:"2rem", margin:"0.5rem 0"}}>

                    {/* Title of form / checkout */}
                    <Typography  variant="h4" align="center" sx={{alignSelf:"center",color:"primary.main"}}>
                        {info?.Checkout?.Text_Checkout}
                    </Typography>

                    {/* Shows which step the user is on, filling in address, paymentmethod or finishing the payment */}
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, alignSelf:mobileStepper ? "center" : "stretch" }}>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>



                    <form /* className='flex__center-c' */ style={{width: "-webkit-fill-available"}} onSubmit={activeStep !== 1 ? handleNext : Submit}>

                        {/* Steps through the content filling in address, paymentmethod or finishing the order */}
                        {getStepContent(activeStep)}                                               
                        
                        {/* Next page and prev page button */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeStep !== 0 && (
                            <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                {info?.Checkout?.Button_Back}
                            </Button>
                            )}
                            <Button
                                variant="contained"
                                type='submit'
                                disabled={purchaseLoading}
                                endIcon={<SaveIcon />}
                                //onClick={handleNext}
                                sx={{ mt: 3, ml: 1 }}
                            >
                            {activeStep === steps.length - 1 ? info?.Checkout?.Button_PlaceOrder : info?.Checkout?.Button_Next }
                            {purchaseLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                    color: "teal",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    }}
                                />
                            )}
                            </Button>
                        </Box>

                    </form>


                
                <KlarnaIcon  width="10vmax" height="10vmax" />
                <Typography variant='h5' color="error">
                    {message}
                </Typography>

                
                
                </Paper>
            </Box>

     

            {/* Shop Items list */}            
            {!mobileStepper &&                 
                <ShopItemsComponent />
            }

            <SimpleBackdropLoading openbackdrop={loading} />    
           
            
        </Container>
    )
}

export default Checkout

