import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// Mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress';

// REDUX
import { AbortReservation } from '../../slices/reservationReducer'; 
import  { AppDispatch }  from '../../store'

// self
import { Iinfo } from '../../types/interface';

interface Iprops {
    info: Iinfo
}
const AbortReservationPage = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()
	const navigate = useNavigate()

    const { id } = useParams()

    const [message, setMessage] = useState("")
    const [aborted, setAborted] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const Submit = async() => {
		try {
            setLoading(true)
            setMessage("")      
			await dispatch(AbortReservation( { id } ))
                .then(unwrapResult)
                .then((res) => {	
                    if(res?.statusCode === 200){                    
                        setMessage(info?.AbortReservationPage?.Message_Success)
                        setAborted(true)
                        setLoading(false)
                    }else{
                        setMessage(res?.body?.message)
                        setLoading(false)
                    }
                })      
		} catch (error) {
            setMessage(info?.AbortReservationPage?.Message_Error)
            setLoading(false)
		}
	}  

    return (
        <Box className="flex__center-c" height={"100vh"} bgcolor={"primary.main"}>

            <Paper className='flex__center-c' sx={{p:"5rem"}}>

                {!aborted ? 
                <Box className='flex__center-c'>
                    <Typography variant='h6' sx={{color:"grey.500"}}>
                        id: {id}
                    </Typography>
                    <Typography variant='h3' >
                        {info?.AbortReservationPage?.Title}
                    </Typography>

                    <Button onClick={Submit} variant="outlined" sx={{m:"3rem 0"}} disabled={loading} >
                        {info?.AbortReservationPage?.Button_Abort}
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "teal",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>

                    <Typography variant='h4' color="error" >
                        {message}
                    </Typography>

                </Box>
                :
                <Box className='flex__center-c'>
                    <Typography variant='h6' sx={{color:"grey.500"}}>
                        id: {id}
                    </Typography>
                    <Typography variant='h3' color="#2ccd1d">
                        {message}
                    </Typography>

                    <Button onClick={()=>navigate("/")} variant="outlined" sx={{m:"3rem 0"}} color='success'> 
                        {info?.AbortReservationPage?.Button_Home}
                    </Button>
                </Box>
                }

            </Paper>

        </Box>
    )
}

export default AbortReservationPage