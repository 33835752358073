import { configureStore, combineReducers } from "@reduxjs/toolkit";
import fetchReducer from './slices/fetchReducer'
import reservationReducer from './slices/reservationReducer'
import shopReducer from './slices/shopReducer'
import cartReducer from './slices/cartReducer'


export type AppDispatch = typeof store.dispatch

const reducer = combineReducers({   
    fetchReducer: fetchReducer,
    reservationReducer: reservationReducer,
    shopReducer: shopReducer,
    cartReducer: cartReducer

})

const store = configureStore({
    reducer: reducer,  
})



export default store

