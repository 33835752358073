// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery';

interface Iprops {
    title: string | undefined
    eventTitle: string
    image: string
    width: number
    height: number
}

const ImageFrame = (props:Iprops) => {
    const { title, eventTitle, image, width, height } = props

    const matches = useMediaQuery('(max-width:690px)');

    const autoHeight = matches ? `${height}vmax` : `${height}vmin`
    const autoWidth = matches ? `${width}vmax` : `${width}vmin`
    return (
        <Box
            className="flex__center-r"
            sx={{              
                position: "relative",              
                width: autoWidth,
                height: autoHeight
            }}>
            <Box className="flex__center-c" width="65%"  height="90%" mb="4px" sx={{alignItems:"flex-start"}}>

                <Box width="100%" height="100%" sx={{border:"2px solid #ffffffb0",borderBottom:"none",borderRight:"none"}}>

                </Box>

                <Typography   variant='h3' color="primary.contrastText" style={{}}> 
                    {title}
                </Typography>

                <Typography /* className='wrap-3-row' */ variant='h4' color="primary.contrastText" sx={{pb:"1rem",textAlign:"left",width:"fit-content"}}>
                    {eventTitle} 
                </Typography>

            </Box>

            <Box width="25%"  height="90%" sx={{border:"2px solid #ffffffb0",borderLeft:"none"}}>

            </Box>
            

            
            
            <img className="header-img-noblur" src={`https://${process.env.REACT_APP_CDN_URL}/${image}`} alt='frameevent' style={{width:"100%"}} onError={(event)=> process.env.REACT_APP_DEFAULTIMAGE ? event.currentTarget.src = process.env.REACT_APP_DEFAULTIMAGE : ""}/>
        </Box>
    )
}

export default ImageFrame