import { useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { MainLoading, Logo } from '../../components'
import { Iinfo } from '../../types/interface'
import { theme } from '../../App'


interface Iprops {
    info: Iinfo
}

const Welcomepage = (props:Iprops) => {
    const { info } = props
    const [loading, setLoading] = useState(true)
    const header_ref = useRef(null)

    const navigate = useNavigate()

    const imageStyle = {
        width: "100vw"
    }



    return (
        <Box component="section" className="flex__center-c" ref={header_ref} sx={{ position:"relative", gap:"2rem", minHeight:"100vh", borderBottom:`5px solid ${theme.palette.primary.main}`}} >

            {/* Image */}
            <img className='header-img-noblur' style={imageStyle} src={info?.Welcomepage?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   

      

            <Box >

          {/*   <Grid container spacing={1} className="flex__center-r">
                <Grid item xs={2} className="flex__center-r">
                    
                        <Typography id="welcomepage-title" className='main-title flex__center-r'  variant='h1' color="primary.contrastText" sx={{}} >
                            {info?.Welcomepage?.Title}
                        </Typography>
                    
                </Grid>
                <Grid item xs={5} style={{textAlign:"center"}} className='flex__center-r'>
                    
                        <Box  sx={{}}> 
                            <Logo width={6} height={6} fill={ theme.palette.primary.main }/>
                        </Box>
                    
                </Grid>
                <Grid item xs={2} className="flex__center-r" sx={{}}>
                    
                        <Typography id="welcomepage-title" className='main-title flex__center-r'  variant='h1' color="primary.contrastText" sx={{}} >
                            {info?.Welcomepage?.Title2}
                        </Typography>
                    
                </Grid>
            </Grid> */}
            <Box className="flex__center-r">
                <Box sx={{width:"400px",justifyContent:"flex-end"}} className="flex__center-r">
                    
                        <Typography id="welcomepage-title" className='main-title'  variant='h1' color="primary.contrastText" sx={{}} >
                            {info?.Welcomepage?.Title}
                        </Typography>
                    
                </Box>
                <Box >
                    
                        <Box  sx={{}}> 
                            <Logo width={6} height={6} fill={ theme.palette.primary.main }/>
                        </Box>
                    
                </Box>
                <Box sx={{width:"400px",justifyContent:"flex-start"}} className="flex__center-r">
                    
                        <Typography id="welcomepage-title" className='main-title'  variant='h1' color="primary.contrastText" sx={{}} >
                            {info?.Welcomepage?.Title2}
                        </Typography>
                    
                </Box>
            </Box>


            </Box>
            

            {/* Buttons */}
            <Box id="welcomepage-buttons" className="flex__center-r flex__r-c" sx={{gap:"2rem"}}>
                
                <Button variant='contained' color='primary' size='large' sx={{width:"10rem" }} onClick={()=>navigate(info?.Welcomepage?.Button_Menu?.Link)}>
                    {info?.Welcomepage?.Button_Menu?.Title}
                </Button>           

                <Button variant="contained" color='primary' size='large' sx={{width:"10rem" }} onClick={()=>navigate(info?.Welcomepage?.Button_Reservation?.Link)}>
                    {info?.Welcomepage?.Button_Reservation?.Title}
                </Button>
            </Box>


            {/* Message / Other */}
            <Typography id="welcomepage-other" variant='h4' color="primary.dark">
                {info?.Welcomepage?.SubTitle}
            </Typography>

           

            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }
            

            
        </Box>
    )
}

export default Welcomepage