import { useState, useEffect } from 'react'

// mui
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

// mui icons
import DownloadIcon from '@mui/icons-material/Download'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// redux
import { addToCart } from '../../slices/cartReducer';
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'

//self
import { Logo } from '../index'
import { theme } from '../../App'
import { Itakeawayreturnitem } from '../../types/interface'
import { BackDropIntro, MainLoading } from '../../components'


interface introtextinit { [key:string]:string | undefined }


interface Imenucomponent {
    menu: {[key:string]: Itakeawayreturnitem[]}  
    info: { [key:string]: string } | undefined      
    type: string  
}
const downloadButtonInclude = ["menu","lunch","takeaway"]
const buyButtonInclude = ["leftover","takeaway"]
const MenuComponent = (props:Imenucomponent )=> {   
    const { menu, info, type } = props
    const dispatch: AppDispatch = useDispatch()
   
    return(
        <Box>
            {menu && Object?.keys(menu)?.map( (key: string, i:number) => {
            
                    
                return (
                    <Grid container spacing={0} sx={{marginBottom:"2rem"}} key={`gridcontainer${i}`}> 

                        <Grid xs={12} sx={{padding:"0 1rem"}} item>
                            <Typography variant='h3' sx={{margin:"2rem 0",color:"primary.main", textAlign:"left"}} paragraph >
                                {key?.split("_").join(" ")}
                            </Typography>
                        </Grid>

                        
                        
                        {menu?.[key].map((menuitem: Itakeawayreturnitem,j:number) => {
                       
                            if(!menuitem?.active && buyButtonInclude?.includes(type)){
                                return
                            }else{
                                return(
                                    <Grid xs={12} md={6} sx={{padding:"0 1rem", marginBottom:"1rem"}} key={`gridmenuitem${j}${i}`} item>

                                        <Box className='flex' style={{justifyContent:"space-between", padding:"0"}} >
                                            <Typography variant='h4' sx={{color:"grey.900"}}  >  {menuitem?.title} </Typography>
                                            {/* <Typography variant='subtitle1' sx={{color:"grey.700"}}  >  {menuitem?.price} </Typography> */}
                                            <Typography variant='subtitle1' sx={{color:"grey.700"}}  >
                                                {menuitem?.price}
                                                {type === "leftover" && <Typography component="s" variant='subtitle1' color="error">  {menuitem?.oldPrice}</Typography>}
                                            </Typography>
                                        </Box>

                                        <Typography variant='subtitle1' sx={{textAlign:"left",color:"grey.700"}}  >  {menuitem?.info} </Typography>
                                        {buyButtonInclude?.includes(type) && 
                                            <Button variant="outlined" size='small' onClick={()=> addToCart && dispatch(addToCart(menuitem)) }>{info?.Button_Buy}</Button>
                                        }
                                    </Grid>
                                )
                            }
                        })}
                
                        

                    </Grid>
                )
            })}

        </Box>
    )
}
interface Iprops {
    menu: {
        [key:string]: Itakeawayreturnitem[]
    } 
    info: { [key:string]: string } | undefined
    editInfo:{ Message: string} | undefined
    type: string
    week?: number
    weekNow?: number
    ChangeWeek?(props:string): void        
    active?: boolean
    menuLoading: boolean
}



const MenuList = (props:Iprops) => {
    const { menu, info, editInfo, type, week=0, weekNow=0, ChangeWeek= ()=>{}, active=true, menuLoading } = props



    const [loading, setLoading] = useState(true)
    const [imageLoading, setImageLoading] = useState(true)


   
    // check Loading from menu and image
    useEffect(() => {        
        if(!menuLoading && !imageLoading){
            setLoading(false)
        }    
      return () => {        
      }
    }, [menu,imageLoading,menuLoading])

    
    const IntroText = (props:introtextinit) => {       
        const { text } = props
        const specialTextColor = {color: theme.palette.primary.main}
        const finalArr = text?.split(" ").map((word,i) => {
            if(i === 1 || i === 5) {
                return <span style={specialTextColor} key={`word${i}`}> {word} </span>
            }
            return word + " "
        })
        return <span >{finalArr}</span>    
    }


    return (
    <Box component="section" className="flex__center-c"  sx={{minHeight:"100vh", position:"relative", justifyContent:"space-around"}} >

        {/* Image */}
        <Box  sx={{height:"60vh", width:"100vw"}} >            
            <img style={{objectPosition:"0 20%"}} className="menu-img-container" src={info?.Image} alt="header" onLoad={()=>setImageLoading(false)} onError={()=>setImageLoading(false)}/>   
        </Box>

        {/* INFO */}
        <Box sx={{minHeight:"100vh", width:"95vw", position:"relative" }}>


            {/* Intro text */}
            <Typography variant='h4' sx={{margin:"2rem 0",color:"#747474"}} paragraph >                    
                <IntroText text={info?.Intro}/>
            </Typography>

            {/* Week Changer */}
            {type === "lunch" && 
            <Box className="flex__center-r">
                <IconButton color='secondary' disabled={ week  === weekNow ? true : false} onClick={()=> ChangeWeek("dec")}>
                    <ArrowBackIosNewIcon  />                        
                </IconButton>

                <Typography variant='h4' color="text.secondary">
                    {info?.Week} {week}
                </Typography>

                <IconButton color='secondary' disabled={ week + 1 >= weekNow + 5 ? true  : false} onClick={()=> ChangeWeek("inc")}>
                    <ArrowForwardIosIcon  />
                </IconButton>
            </Box>
            }

            {/* Icon */}
            <Divider flexItem variant='middle'>
                <Box sx={{width: "50px", height:"50px"}}>                  
                    <Logo height={8} width={8} fill={ theme.palette.primary.main } navigateOn={false} />
                </Box>
            </Divider>


           

            {/* Menu */}
            {(Object.keys(menu).length > 0 && active) ? 
            <MenuComponent menu={menu} info={info} type={type} />
            :
            <Typography variant='h3' m="2rem 0">
                {info?.NoContent_Message}
            </Typography>
            }


            <Box sx={{position:"absolute",zIndex:-1,/* opacity:0.3,  */width:"100%",height:"100%", top:0,bottom:0,left:0,right:0/* ,bgcolor:BackgroundColor */}}>
                <Logo  fill={ theme.palette.primary.main } navigateOn={false} opacity={0.05}/>
            </Box>

            { (editInfo?.Message || !active) &&<Typography variant='subtitle1'>{editInfo?.Message}</Typography>}

        </Box> 



        {/* Download button */}
        {downloadButtonInclude.includes(type) && Object.keys(menu).length > 0 &&
        <Button 
            variant="outlined"
            endIcon={<DownloadIcon />}
            sx={{margin:"2rem 0",color:"primary.main", borderColor:"primary.main"}}
            //download={`https://${process.env.REACT_APP_CDN_URL}/pdf/${type}${type === "lunch" ? week : ""}.pdf`} 
            target='_blank'
            rel="noopener noreferrer"
            href={`https://${process.env.REACT_APP_CDN_URL}/pdf/${type}${type === "lunch" ? week : ""}.pdf`}> 
            {info?.Button_Download}
        </Button>
        } 

        {/* Intro Backdrop title */}         
        <BackDropIntro title={info?.Backdrop_Title}/>


        {/* LOADING SCREEN */}
        {loading && 
            <MainLoading loading={loading} />
        }

    </Box>

    )
}

export default MenuList