import { useState, useEffect, Fragment} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


/* mui */
import { responsiveFontSizes, createTheme, ThemeProvider } from '@mui/material/styles';

//* REDUX */
import  store  from './store'
import { useDispatch } from 'react-redux'
import { GetGallery,GetMenu, GetEvent, GetEditInfo } from './slices/fetchReducer'

/* Self Imports  */
import { Navbar, Footer } from './components/index'
import { Welcomepage, WelcomePageDoubleSection, Reservation, Gallery, Menu, Lunch, LunchWeekend, Takeaway, Leftover, About, Event, EventPage, EventFrontPage ,  AbortReservationPage, Checkout, SuccessPayment } from './containers/index'
import { Iinfo, IeditInfo, Ieventitem } from './types/interface'




export let theme = createTheme({    
    palette: {
        primary: {
            main: '#0073b5',                       
            dark: '#005393' ,
            light: "#80d1f5",
            contrastText:"#ffffff"
        
            
        },
        secondary: {
            main: '#b64600',
            light:"#b64600",
            dark:"#3c70d7",
            contrastText:"#000000"
            
        },
        info: {
            main: "#ffffff",
            dark:"#e0e0e0",
        },
        text: {
            primary: "#000000",
            //secondary:
            
        },
        
        
    },
    typography: {    
        h1: {            
            //color: 'text.secondary',            
            textAlign: 'center',
            fontSize:"5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"5vmax",
            } 
        },
        h2: {            
            //color: 'text.secondary',
            textAlign: 'center',
            fontSize:"4vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"4vmax",
            } 
        },
        h3: {            
            //color: 'text.secondary',
            textAlign: 'center',
            fontSize:"3vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"3vmax",
            }  
        },
        h4: {            
            //color: 'text.secondary',
            textAlign: 'center',
            fontSize:"2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"2vmax",
            } 
        },
        h5: {            
            //color: 'text.secondary',
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            } 
        },
        h6: {            
            //color: 'text.secondary',
            textAlign: 'center',
            fontSize:"1.2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.2vmax",
            } 
        },
        subtitle1: {
            //color:"text.secondary",
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            }  
        },
        subtitle2: {         
           // color:"text.secondary",   
            textAlign: 'center',
            fontSize:"0.8vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"0.8vmax",
            } 
        },      
        button: {            
            textAlign: 'center',
            //fontSize:"1vmax",
            fontWeight:"400" 
        },
     
      },
    components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              h1: 'h1',
              h2: 'h2',
              h3: 'h3',
              h4: 'h4',
              h5: 'h5',
              h6: 'h6',              
              subtitle1: 'p',
              subtitle2: 'h2',
              body1: 'span',
              body2: 'span',
            },
          },
        },
        MuiButton: {
            defaultProps: {          
                color:"secondary",   
                sx:{
                    fontSize:"1.2vmin",  
                    "@media (max-width:600px)": {
                        fontSize:"1.2vmax",
                    }, 
                    fontWeight:"400"
                }                                                             
            },
           
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                  '& .MuiOutlinedInput-root': {
                    
                    '&:hover fieldset': {
                        borderColor: '#d7a33c',
                    },
                   
                  },
                },
              },
            defaultProps: {          
                //color:"info",     
                sx:{
                
                    input: {
                        color:"text.secondary",   
                                              
                    },                          
                    label:{
                        color:"text.secondary",                             
                    },
                    fieldset: {
                        borderColor:"text.secondary",
                      
                    } 
                }        
            },
        },
        MuiMobileStepper:{
            defaultProps: {          
                color:"text.secondary !important",                   
                sx:{
                    '& .MuiMobileStepper-progress':{
                        color:"text.secondary !important",
                    }
                }
            }
        },
        MuiStepLabel:{
            defaultProps: {                                        
                sx:{                    
                    '& .MuiStepLabel-label.Mui-active':{
                        color:"secondary.dark",
                    },
                    '& .MuiStepLabel-label.Mui-completed':{
                        color:"secondary.main",
                    },
                    
                }
            }
        },
      
        MuiRadio:{
            defaultProps: {          
                //color:"info",                    
                sx:{
                    '& .MuiRadio-root':{
                       // color:"#ffffff"
                    },                    
                    '& .Mui-checked':{
                        //color:"text.secondary"
                    }/* ,
                    '& .Mui-colorPrimary':{
                        color:"#ffffff"
                    },
                    '& .Mui-colorSecondary':{
                        color:"#ffffff"
                    } */
                
                }
            }
        },
        MuiPagination:{
            defaultProps: {                          
                sx:{
                   
                    '& .MuiButtonBase-root': {
                        borderRadius:"50%",
                        fontSize:"1vmax",
                        width:"2.5vmax",
                        minHeight:"2.5vmax",
                    },
                    '& .MuiSvgIcon-root': {                                                
                        width:"2.5vmax",
                        height:"2.5vmax",
                    },
                }
            }
        },
        MuiBottomNavigation:{
            defaultProps: {                          
                sx:{
                   
                    '& .Mui-selected': {
                        color:"red"                    
                    },
                }
            }
        }
    },
});
theme = responsiveFontSizes(theme);

const App = () => {
    const defaultTheme = theme;

    type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
 


    //* Get Info */
    const [info, setInfo] = useState<Iinfo>({} as Iinfo)
    useEffect(() => {
        fetch('/info/info.json')
            .then(res => res.json())
            .then((res) => {                       
                setInfo(res)
            })            
        return () => {}
    }, [])

    interface Imenu { 
        title: string
        info: string        
        price: number
        category: string
    }
       
    interface Ievent {
        Event:Ieventitem []
        FrontPage: string
    }

    const [editInfo, setEditInfo] = useState<IeditInfo >({} as IeditInfo)
    const [eventAll, setEventAll] = useState<Ievent>()
    const [frontPage, setFrontPage] = useState(false)
    const [menu, setMenu] = useState<Imenu []>([])
    const [menuCategory, setMenuCategory] = useState<string []>([])
    
    const [event, setEvent] = useState<Ieventitem>({
        id: "",
        title: "",
        info: "",
        images: [],
    })
   
    useEffect(() => {    

        dispatch(GetEvent({}))
            .unwrap()
            .then(res => {            
               
          
                const data = res?.body?.data
                setEventAll(data)
                
                
                const tempevent =  data?.Event.filter((obj:Ieventitem) => obj.id === data?.FrontPage)           
                if(tempevent?.length > 0){
                    setEvent(tempevent[0])                
                    setFrontPage(true)
                }
                
            })
            .catch(err=> console.log(err))

    
        dispatch(GetGallery({}))
            .unwrap()
            .then(res => {
                    
        })


   

        dispatch(GetEditInfo({}))
            .unwrap()
            .then(res => {
                
                const data = res?.body?.data                
                setEditInfo(data)
        })

        
  

        
        
        

      
           
        dispatch(GetMenu({}))
            .unwrap()
            .then(data => {
               
                setMenu(data?.Menu) //data?.Menu
                setMenuCategory(data?.Category) //data?.MenuCategory
        })

        return () => {
        
        }

        

        
        
            
    
            
    

    }, [])


    return (
        <Router >
            <ThemeProvider theme={defaultTheme}>                             
                    <Navbar info={info} editInfo={editInfo} />
                    <Routes >                            
                        <Route  path="" index element={
                            <Fragment>                                
                                <Welcomepage info={info}  />
                                { frontPage && <EventFrontPage info={info} event={event} />}
                                <WelcomePageDoubleSection info={info?.WelcomepageDoubleSection1}  direction='right'/>
                                <WelcomePageDoubleSection info={info?.WelcomepageDoubleSection2}  direction='left'/>
                                <WelcomePageDoubleSection info={info?.WelcomepageDoubleSection3}  direction='right'/>
                            </Fragment>
                        } />                                                    
                        <Route path="/reservation" element={<Reservation info={info} />} />
                        <Route path="/abortreservationpage/:id" element={<AbortReservationPage info={info} /> } />
                        
                        

                        <Route path="/gallery" element={<Gallery info={info} />} />
                        <Route path="/menu" element={<Menu info={info} editInfo={editInfo} menuprops={menu} menucategoryprops={menuCategory} />} />
                        <Route path="/lunch" element={<Lunch info={info} editInfo={editInfo} />} />
                        <Route path="/lunchweekend" element={<LunchWeekend info={info} editInfo={editInfo}  />} />
                        <Route path="/takeaway" element={<Takeaway info={info} editInfo={editInfo} />} />
                        <Route path="/Leftover" element={<Leftover info={info} editInfo={editInfo} />} />
                        <Route path="/about" element={<About info={info}  />} />

                        <Route path="/event" element={<Event info={info} eventprops={eventAll?.Event} />} />
                        <Route path="/event/:id" element={<EventPage info={info} editInfo={editInfo}/> } />                            

                        <Route path="/checkout" element={<Checkout info={info}  />} />
                        <Route path="/successpayment" element={<SuccessPayment info={info}  />} />
                     
                        
                    </Routes>                                                                          
                    <Footer info={info} editInfo={editInfo} />                    
            </ThemeProvider>
        </Router>
    )
}

export default App

