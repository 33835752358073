import { GetShop, GetLeftover } from '../slices/shopReducer'



import store from '../store'



export const FetchTakeawayFromStore = async() => {
    try {             
            
        let info = store.getState()?.shopReducer?.takeaway
       
     
        if(info?.items?.length === 0){
            await store.dispatch(GetShop({typeOfMenu: "takeaway"}))
                .unwrap()
                .then((res) => {       

                    info = store.getState()?.shopReducer?.takeaway

                })
                .catch((error) => {
                    console.log(error)                                        
                    return {statusCode: 500, data: {items: [], categories: [] }}
                })
        }
        return {statusCode: 200, data: info}

    } catch (error) {
        console.log("Info error",error)              
        return {statusCode: 500, data: {items: [], categories: [] } }
    }

}



export const FetchLeftoverFromStore = async() => {
    try {             
            
        let info = store.getState()?.shopReducer?.leftover
     
     
        if(info?.items?.length === 0){
            await store.dispatch(GetLeftover({typeOfMenu: "leftover"}))
                .unwrap()
                .then((res) => {                            
                    info = store.getState()?.shopReducer?.leftover                                                      
                })
                .catch((error) => {
                    console.log(error)                                        
                    return {statusCode: 500, data: {items: [], categories: [] }}
                })
        }
        return {statusCode: 200, data: info}

    } catch (error) {
        console.log("Info error",error)              
        return {statusCode: 500, data:{items: [], categories: [] }}
    }

}