import { useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'


import NavigateNextIcon from '@mui/icons-material/NavigateNext';


// self
import { Iinfo, Ieventitem } from '../../types/interface'
import { BackDropIntro } from '../../components/index'


interface InewsComponent {
    info: Iinfo | undefined
    event: Ieventitem     
}
const NewsComponent = (props:InewsComponent) => {
    const { event, info } = props
   // console.log(" process.env.REACT_APP_DEFAULTIMAGE", process.env.REACT_APP_DEFAULTIMAGE)
    const navigate = useNavigate()

    return (
        <Grid item xs={12} sm={6} md={4} className="flex__center-c " key={`newscomponent${event?.id}`}
            sx={{
         
                flexDirection:"column !important",
                justifyContent:"flex-start",
                                               
            }}
        >


            {/* Image */}
            {/* <Box className="event-image-box" sx={{marginBottom:"2rem"}}>
                <img 
                    src={`https://${process.env.REACT_APP_CDN_URL}/${event?.images[0]}`}
                    alt={event?.title}
                   
                />
            </Box> */}
            <Box className="" sx={{marginBottom:"2rem"}}>
                <img 
                    src={`https://${process.env.REACT_APP_CDN_URL}/${event?.images[0]}`}
                    //src={`${event?.images[0]}`}
                    alt={event?.title}                    
                    style={{height: '30vmax', width: '25vmax', objectFit:"cover"}}
                    onError={(event)=> process.env.REACT_APP_DEFAULTIMAGE ? event.currentTarget.src = process.env.REACT_APP_DEFAULTIMAGE : ""}
                   
                />
            </Box>


            {/* Text Area */}
            <Box className="flex__center-c">

                {/* Title */}
                <Typography variant='h4' color="secondary" paragraph>
                    {event?.title}
                </Typography>

                {/* Info text */}
                <Typography className='wrap-3-row' maxWidth={"100%"} variant='subtitle1' paragraph  >
                    {event?.info}
                </Typography>


                {/* read more button */}
                <Button endIcon={<NavigateNextIcon  color="secondary"  sx={{width:"1.5vmax",height:"1.5vmax"}}/>} onClick={()=>navigate(`/event/${event?.id}`,{state:event}) }>
                    <Typography  variant='button' color="secondary"  >
                        {info?.Event?.ReadMore}
                    </Typography>
                </Button>
                
            </Box>

        </Grid>
    )
}











//* MAIN  */   
interface Iprops {
    info: Iinfo | undefined
    eventprops: Ieventitem [] | undefined
  
}
const Event = (props:Iprops) => {    		   
    const { info, eventprops } = props

   
    const header_ref = useRef(null)
 
    useEffect(() => {      
        window.scrollTo(0, 0);
    },[])



    return (
        <Container className="flex-c" maxWidth={false} ref={header_ref}  sx={{minHeight:"100vh", position:"relative"/* , bgcolor:"primary.dark" */}} >

       

            
            <Grid container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"10rem !important"}>
                {eventprops?.map(event => {
                    
                    return(
                        <NewsComponent {...{event,info}} key={`newscomponent${event.id}`}/>
                    )
                })}



            </Grid>

            <Divider  variant='middle' flexItem light/>


            {/* Intro Backdrop title */}         
            <BackDropIntro title={info?.Event?.Backdrop_Title}/>

        </Container>
    )
}

export default Event