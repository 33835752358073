import React from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery';

import { Logo } from '../../components/index'
import { theme } from '../../App';


interface Iprops {
    info: {
        "Title": string,
        "SubTitle":string
        "Description":string
        "Image":string
        "Direction":string
        "Button": {
            "Link": string
            "Title": string
        }
    } 
    direction: string
}
const WelcomePageDoubleSection = (props:Iprops) => {    
    const { info, direction } = props
    
    const navigate = useNavigate()

    const windowSize = useMediaQuery('(max-width:690px)');
    
    const headerStyle = {
        column: {
            display:"flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
     
        },
        row: {
            display:"flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            
        }
    }
    const ImageBoxStyle = {
        column: {
            width: "100vw",
            height: "50vh",
            position: "relative",
            
        },
        row: {
            width: "50vw",
            height: "50vh",
            position: "relative",
            order: direction === "left" ? 1:2

        }
    }
    const TextBoxStyle = {
        column: {
            width: "100vw",
            height: "50vh",
            position: "relative",
            
        },
        row: {
            width: "50vw",
            height: "50vh",
            position: "relative",
            order: direction === "left" ? 2:1

        }
    }
    const TextColor = direction === "left" ?  "" : theme.palette.primary.contrastText 
    const TitleColor = direction === "left" ?  theme.palette.primary.dark : theme.palette.primary.contrastText 
    const BackgroundColor = direction === "left" ?  "" : theme.palette.primary.main 
    const LogoColor = direction === "left" ? theme.palette.primary.main : theme.palette.primary.contrastText
    
    return (
        <Box sx={ windowSize ?  headerStyle.column :  headerStyle.row }>

            {/* Image Box */}            
            <Box sx={ windowSize ?  ImageBoxStyle.column :  ImageBoxStyle.row } >
                <img className='menu-img-container' src={info?.Image} alt="welcomepagedoubesection" />
            </Box>


            {/* Text Box */}
            <Box sx={ windowSize ?  TextBoxStyle.column :  TextBoxStyle.row } className="flex__center-c" >
                

                <Typography variant='h4' color={TextColor}>
                    {info?.SubTitle}
                </Typography>

                <Typography variant='h2' color={TitleColor}>
                    {info?.Title}
                </Typography>

                <Typography variant='subtitle1'  color={TextColor}>
                    {info?.Description}
                </Typography>

                <Button variant="contained" color={direction === "left" ? "primary" : "info"} onClick={()=>navigate(info?.Button?.Link)}>
                    {info?.Button?.Title}
                </Button>

                <Box sx={{position:"absolute",zIndex:-1,/* opacity:0.3,  */width:"100%",height:"100%",bgcolor:BackgroundColor}}>
                    <Logo  fill={ LogoColor } navigateOn={false} opacity={0.2}/>
                </Box>

            </Box>




        </Box>
    )
}

export default WelcomePageDoubleSection