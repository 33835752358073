import { useRef } from 'react'
import { useNavigate } from 'react-router-dom';

import { Typography, Box, Button } from '@mui/material'


import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { Iinfo, Ieventitem } from '../../types/interface';
import { Logo, ImageFrame } from '../../components';
import { theme } from '../../App';



const boxStyles = {         
    width:"45%",
    '@media (max-width: 650px)': {
        width:"90%",
    },
    marginTop:"2rem"
};

 
interface propsinit { 
    event: Ieventitem  
    info: Iinfo
}

const EventFrontpage = (props:propsinit) => {
    const eventfrontpageRef  = useRef(null)
    const { event, info }  = props       
  
    const navigate = useNavigate()

    return (
        <Box className="flex__center-c"  sx={{ /* bgcolor: "primary.main" , */minHeight:"100vh",p:"2rem 0"}}  ref={eventfrontpageRef} /* bgcolor="primary.main" */> 

            <Typography variant='h1' color="primary.main">
                {info?.EventFrontpage?.Title}
            </Typography>


            <Box className="flex__center-r flex__r-c" sx={{justifyContent:"space-around"}} width="90%">

                <Box className="flex__center-c" sx={boxStyles}>                          
                    <ImageFrame title={info?.EventFrontpage?.Image__Title} eventTitle={event?.title} image={event?.images[0]} width={35} height={35}/>                    
                </Box>

                <Box className="flex__center-c" sx={boxStyles} >

                    <Logo height={12} width={12} fill={ theme.palette.primary.main } />

                    <Typography  variant='subtitle1' sx={{textAlign:"left", marginBottom:"2rem",width:"90%"}}>
                        {event?.info}
                    </Typography>

                    <Button color='secondary' variant="outlined" startIcon={<CalendarTodayIcon />} onClick={()=>navigate(info?.EventFrontpage?.Button?.Link)}>
                        {info?.EventFrontpage?.Button?.Title}
                    </Button>
                </Box>


            </Box>
                              
        </Box>
    )
}

export default EventFrontpage