import {useState, useLayoutEffect} from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


interface Iprops {
    openbackdrop: boolean
}
export default function SimpleBackdropLoading(props:Iprops) {
    const { openbackdrop }  = props;
    const [open, setOpen] = useState(openbackdrop);

    useLayoutEffect(() => {
        setOpen(openbackdrop)
    
        return () => {
        
        };
    }, [openbackdrop])
  
  
   

    return (
        <div>      
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        </div>
    );
    }