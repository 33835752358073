import { createSlice , PayloadAction} from '@reduxjs/toolkit'
import { Ipayload } from '../types/interface'
//localStorage.setItem("cart",JSON.stringify([{["pk"]:"123123",a:123123,quantity:1}, {["pk"]:"1232323",quantity:1},{["pk"]:"454545",l:123123132123132,quantity:1}])) 
const getTotalprice = () =>{
    const cart = getCart()   
    let count = 0
    if(cart.length === 0){
        return 0
    }
    cart.forEach((item :Icart) => {
        count = item?.quantity*item.price + count
    })             
    return count 
}
const getNumberItems = () =>{
    const cart = getCart()   
   
    let count = 0
    if(cart.length === 0){
        return 0
    }
    cart.forEach((item :Icart) => {
        count += item.quantity
    })             
    return count
}
const getCart = () => {    
   
    const storageCart = localStorage.getItem("cart")
    
    
    if(!storageCart){
        return []        
    }else {
        return JSON.parse(storageCart)
    }
}


type Icart = Ipayload & { quantity:number } 
interface Istorage {
    pk:string
    sk:string
    quantity:number
    title:string
    price:number
}
const Cart = createSlice({
    name:"cart",
    initialState: {cart:getCart(),length:getNumberItems(),totalprice:getTotalprice()},
    reducers: {       
        addToCart(state, action: PayloadAction<Ipayload>){
            try {                          
               
                const stateCart:Istorage [] = state.cart
                const storageCart:Istorage [] = getCart() 
                const storagePayload = {
                    "pk": action.payload["pk"],
                    "sk": action.payload["sk"],
                    quantity: 1,
                    title: action.payload["title"],
                    price: action.payload["price"],
                    /* images: action.payload["images"],
                    size: action.payload["size"],
                    color: action.payload["color"] */
                }

                // if cart is empty
                if(stateCart.length === 0) {
               
                    localStorage.setItem("cart",JSON.stringify([storagePayload])) 
                    state["cart"] = [storagePayload]
             
                    state["length"] = getNumberItems()
                    state["totalprice"] = getTotalprice()
                    
                   
                }else{                                
                    // adds 1 more on quantity                    
                    const indexFound = stateCart.findIndex((e: Istorage) => e["pk"] === action.payload["pk"])                                
                    if(indexFound !== -1 ){         
                    
                        
                        stateCart[indexFound]["quantity"] += 1
                        storageCart[indexFound]["quantity"] += 1
                    
                                                
                    }else {
                    
                        stateCart.push(storagePayload)
                        storageCart.push(storagePayload)
                    }
                
                    localStorage.setItem("cart",JSON.stringify(storageCart))   
                    state["cart"] = stateCart
                    state["length"] = getNumberItems()
                    state["totalprice"] = getTotalprice()
                }
            //return state
            } catch (error) {
                   
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },
        removeQuantityFromItem(state, action:PayloadAction<Ipayload>){
            try {                            
                
                const storageCart: Istorage [] = getCart()         
                const stateCart: Istorage [] = state.cart                         
                const indexFoundStorage = storageCart.findIndex((e) => e["pk"] === action.payload["pk"])
                const indexFoundState = stateCart.findIndex((e) => e["pk"] === action.payload["pk"])
             
                if(indexFoundStorage !== -1 && indexFoundState !== -1){
                    if(storageCart[indexFoundStorage]["quantity"] === 1 || stateCart[indexFoundState]["quantity"] === 1){
                      
                        storageCart.splice(indexFoundStorage, 1)
                        stateCart.splice(indexFoundState, 1)
                    }else if(storageCart[indexFoundStorage]["quantity"] <= 0 || stateCart[indexFoundStorage]["quantity"] <= 0){
                   
                    }else{
                      
                        storageCart[indexFoundStorage]["quantity"] -= 1
                        stateCart[indexFoundState]["quantity"] -= 1
                    }
                    localStorage.setItem("cart",JSON.stringify(storageCart))   
                    state["cart"] = stateCart
                    state["length"] = getNumberItems()
                    state["totalprice"] = getTotalprice()
                }
                //return state
            } catch (error) {
                   
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },                
        removeItemCart(state, action:PayloadAction<Ipayload>){
            try {                            
                const storageCart = getCart()         
                const stateCart = state.cart                         
                const indexFoundStorage = storageCart.findIndex((e: any) => e["pk"] === action.payload["pk"])
                const indexFoundState = stateCart.findIndex((e:any) => e["pk"] === action.payload["pk"])
                if(indexFoundStorage >= 0 && indexFoundState >= 0){                
                    storageCart.splice(indexFoundStorage, 1)            
                    stateCart.splice(indexFoundState, 1)
                }
                localStorage.setItem("cart",JSON.stringify(storageCart))   
                state["cart"] = stateCart
                state["length"] = getNumberItems()
                state["totalprice"] = getTotalprice()
                return state
            } catch (error) {
                  
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },
        clearCart(state, action){
            try {                            
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
                return state
            } catch (error) {
                 
                localStorage.clear();
                state["cart"] = []
                state["length"] = 0
                state["totalprice"] = 0
            }
        },
        
    }
})

const { reducer } = Cart

export const { addToCart, removeQuantityFromItem, removeItemCart, clearCart } = Cart.actions

export default reducer