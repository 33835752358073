import { useEffect, useState } from 'react';


/* Datepicker */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'

// REDUX
import { GetLunchWeekend } from '../../slices/fetchReducer'; 
import  store  from '../../store'
import { useDispatch } from 'react-redux'

// Self
import { MenuList } from '../../components'
import { Iinfo, IeditInfo } from '../../types/interface';
import { ReStructureMenu } from '../../functions/ReStructureMenu';

dayjs.extend(weekOfYear)


interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
    
}

const LunchWeekend = (props:Iprops) => {
    const { info, editInfo } = props

    type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
      

    const [menu, setMenu] = useState({})
    
    const [week, setWeek] = useState<number>(dayjs().week())
    const [weekNow] = useState<number>(dayjs().week())
    const [menuLoading, setMenuLoading] = useState<boolean>(true)

    //* Rerender props */
    useEffect(() => {              
        dispatch(GetLunchWeekend({week:week}))
            .unwrap()
            .then(data => {            
                
                setMenuLoading(false)
                setMenu(ReStructureMenu(data?.Menu, data?.Category))           
                
            }).catch((e: Error) => {                 
                setMenuLoading(false)
                return
            });

    }, [week, info])


    //* Window scroll   */
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            
        }
    }, [])
    
    

   
    // Changes Week back and forth, max 5 weeks forward. 
    const ChangeWeek = (dir:string) => {        
        const nextWeek = dir === "inc" ?  week + 1 : week - 1
        if(nextWeek >= weekNow + 5)  return 

        const tempWeek = dayjs().week(nextWeek)
        const newWeek = dayjs(tempWeek).week()
        setWeek(newWeek)
      
    }

   
    
 
   
    

    return (
        
        <MenuList menu={menu} info={info?.LunchWeekend} editInfo={editInfo?.LunchWeekend} type={"lunch"} week={week} weekNow={weekNow} ChangeWeek={ChangeWeek} menuLoading={menuLoading}/> 
    )
}

export default LunchWeekend