import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

// mui
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// datpicker
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs, { Dayjs } from 'dayjs';

// mui icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// REDUX
import { useDispatch } from 'react-redux';
import { PostReservation, GetReservationTimes } from '../../slices/reservationReducer'; 
import  store  from '../../store'

// Self
import { FetchInfoFromStore } from '../../functions/Info'
import { FetchReservedDates } from '../../functions/Reservation';
import { Iinfo,Iinfodb} from '../../types/interface'



interface Iprops{
    info: Iinfo
}
const Reservation = (props:Iprops) => {
    const { info } = props
    type AppDispatch = typeof store.dispatch
    const useAppDispatch = () => useDispatch<AppDispatch>()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const header_ref:any = useRef(null)
    const paper_ref:any = useRef(null)

    const theme = useTheme();
    

    const [value] = useState<Dayjs | null>(dayjs());
    const [freeTimes, setFreeTimes] = useState<number []>([])
    const [message, setMessage] = useState<string | undefined>("")
 
    const [dbInformation, setDbInformation] = useState<Iinfodb>({})
    const [page, setPage] = useState<number>(7)   
    const [disabledDates, setDisabledDates] = useState<number []>([])
    const [loading, setLoading] = useState(true)
    interface Iform {
        type: string
        people: number
        date: number
        time: number
        dateNow: number
        firstName: string
        lastName: string
        email: string
        phone: string
        other: string
        duration: number
        approved_storage: boolean
        newsletter: boolean
    }
    const [form, setForm] = useState<Iform>({
        type: "",
        people: 0,
        date: 0,
        time: 0,
        dateNow: new Date().setHours(0,0,0,0),
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        other: "",
        duration:0,
        approved_storage: false,
        newsletter: false
    })
    
    const errorinit = {
        firstName:"",
        lastName:"",
        email:"",
        phone:"",
        message:"",
        approved_storage: "",
        newsletter:""
    }
    const [error] = useState(errorinit) 

   
 
    //* Fetch GetInfo */
    useEffect(() => {
        const asyncfunc = async() => {
            
            const res = await FetchInfoFromStore()
        
            //if(res?.statusCode === 200) setLoading(false)
            setDbInformation(res?.data)         

            if(res?.data?.Reservation_Active) {
                setMessage("")
                setPage(0)
                setLoading(false)
            }else{
                
                
                setMessage(info?.Reservation?.System_Offline_Message || "Vårat Bokningssystem är för tillfället avstängt, för frågor ring eller mail oss")
                setPage(6)                        
                setLoading(false)//
                return
            }   

            const reservarr:any = res?.data?.Reservation_ClosedDates 

            let tempArr = [...disabledDates] 
            let concatArr = tempArr.concat(reservarr)
          
            setDisabledDates([...concatArr])
        }
        asyncfunc()        
        
    },[])
   
    //*!  Temp console.log 
  
    //*!  Temp console.log 
        
    

    //* Check if Reservation system är offline => Error response  */
/*     useEffect(() => {                                 
        if(dbInformation?.Reservation_Active) {
            setMessage("")
            setPage(0)
        }else{
            setMessage(info?.Reservation?.System_Offline_Message)
            setPage(6)                        
        }              
        setLoading(false)
    }, [dbInformation,info])
 */

   

    //* Functions Dispatch
    // When date is pushed, get the free times at that date
    const GetFreeBookingTimes = async(date:number) => {
        NextPage()
        setLoading(true)
        setPage(7)
        await dispatch(GetReservationTimes({date: date, people:form.people, type:form.type}))
            .unwrap()
            .then((res) => {                
                 
                if(res?.statusCode === 200){
                    setFreeTimes(res?.body?.data)
                  //  NextPage()
                }else{                    
                    setMessage(res?.body?.message ||  "Ett fel uppstod, ladda om sidan och försök igen")
                    setFreeTimes([])
                    
                }      
                setLoading(false)
                setPage(3)
                
        })
        //setFreeTimes( [Date.now(),Date.now()+3600000,Date.now()+3600000*2,Date.now()+3600000*3] )
        
    }
    // When People is pushed, get the dates that are Not Free
    const GetOccupiedBookingDates = async(people:number) => {        
        
        setLoading(true)
        const asyncfunc = async() => {
            interface Ireserveddata {
                statusCode: number,
                data: number []
            }
            const res:Ireserveddata = await FetchReservedDates(people, form.type, form.dateNow)         
           
            if(res?.statusCode === 200){
                const tempArr = [...disabledDates]

                let concatArr = tempArr.concat( res?.data )
                setDisabledDates([...concatArr])
                setLoading(false)            
                NextPage()
            }else{              
                setLoading(false)            
                NextPage()
            }           
            
        }
        asyncfunc() 
        //setDisabledDates([])
        setLoading(false)            
        NextPage()
    }   
    const Submit = async(event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        
        setMessage("")
        if(form.firstName === ""){
            setMessage("Ni måste fylla i korrekt förnamn")
            return
        }else if(form.lastName === ""){
            setMessage("Ni måste fylla i korrekt efternamn")
            return
        }else if(form.email === ""){
            setMessage("Ni måste fylla i en korrekt email")
            return
        }else if(!form.approved_storage){
            setMessage("Ni måste acceptera lagring")
            return
        }
        setLoading(true)
        await dispatch(PostReservation(
            {
                people: form.people,
                type:form.type,
                dateNow:form.dateNow,
                date: form.date,
                time: form.time,
                email: form.email,
                firstName: form.firstName,
                lastName: form.lastName,
                approved_storage: form.approved_storage,
                newsletter: form.newsletter,
                other: form.other,
                phone: form.phone,
                duration: form.duration,

            }))
            .unwrap()
            .then((res:any) => {                
                
                if(res?.statusCode === 200){
                    setPage(5)
                    window.scrollTo(0, 0)
                }else{
                    setPage(6)
                }            
                setMessage(res?.body?.message || info?.Reservation?.Error_Text)
                setLoading(false)
                window.scrollTo(0, 0)
            })
    }



  
   


    //* Functions    
    // Enters booking data in to form
    const HandleBooking = (name:string,value:string|number) => {
        setForm(prev => ({...prev, [name]:value}))
        
        if(name === "date"){
            
            //const duration = value === "Lunch" ? dbInformation?.lunchDuration : dbInformation?.dinnerDuration 
            const dayOfBooking = new Date(form?.date).getDay()
            let weekend = false
            if(dayOfBooking === 0 || dayOfBooking === 6){  
                weekend = true
            }
            const duration = GetDuration(form?.people,form?.type,dbInformation,weekend)
            if(!duration) return
         
            const durationMilliSeconds = duration?.$H * 60 * 60 * 1000 + duration?.$m * 60 * 1000
            setForm(prev => ({...prev, duration: durationMilliSeconds }))
        }
    } 
    const GetDuration = (guests:number, type:string, info:any, weekend:boolean) => {
        
        let h 
        let m 
        if(guests > 4 && type==="Lunch"){ 
        
            h = weekend ? info?.lunchDurationWeekend_morePeople?.$H : info?.lunchDuration_morePeople?.$H
            m = weekend ? info?.lunchDurationWeekend_morePeople?.$m : info?.lunchDuration_morePeople?.$m            
        }else if(guests < 5 && type==="Lunch"){
        
            h = weekend ? info?.lunchDurationWeekend?.$H : info?.lunchDuration?.$H
            m = weekend ? info?.lunchDurationWeekend?.$m : info?.lunchDuration?.$m            
        }else if(guests > 4 && type==="Middag"){ 
        
            h = weekend ? info?.dinnerDurationWeekend_morePeople?.$H : info?.dinnerDuration_morePeople?.$H
            m = weekend ? info?.dinnerDurationWeekend_morePeople?.$m : info?.dinnerDuration_morePeople?.$m            
        }else if(guests < 5 && type==="Middag"){
        
            h = weekend ? info?.dinnerDurationWeekend?.$H : info?.dinnerDuration?.$H
            m = weekend ? info?.dinnerDurationWeekend?.$m : info?.dinnerDuration?.$m            
        }
        
        return {$H:h,$m:m} 
    }
    // When pressing the back arrow, navigates one step backword, resets values infront
    /* const HandleGoback = () => {   
        switch (page) {
            case 1:
                setForm(prev=>({...prev,                    
                    people: 0,
                }))
                PrevPage()   
                break;
            case 2:
                setForm(prev=>({...prev,                    
                    date: 0,
                }))
                PrevPage()   
                break;
            case 3:
                setForm(prev=>({...prev,                    
                    time: 0,
                }))
                PrevPage()   
                break;
            case 4:                
                PrevPage()   
                break;
            default:
                break;
        }        
    } */
    // When pressing the different categories, navigates only backwords, resets values infront
   /*  const HandleGobackNav = (category: number) => {
        switch (category) {
            case 0:
                setForm(prev=>({...prev,
                    people: 0,
                    date: 0,
                    time: 0,
                }))
                setPage(prev => category) 
                break;
            case 1:
                setForm(prev=>({...prev,
                    date: 0,
                    time: 0,
                }))
                setPage(prev => category) 
                break;
            case 2:
                setForm(prev=>({...prev,
                    time: 0,
                }))
                setPage(prev => category) 
                break;
            default:
                break;
        }                
    } */
    // Changes page forward
    const NextPage = () => {       
        setMessage("")
        setPage(prev => page + 1)
    }
    // Changes page backward
    const PrevPage = () => {
        setMessage("")
        setPage(prev => page - 1)   
    }
    // Checks all disabledDateds
    const CheckIfDisabled = (date:number) => {
        
        let onlyEpochArray = disabledDates.map((obj:any) => obj?.date)
        return onlyEpochArray?.includes(new Date(date).getTime() )
        
    }
    // Sets the chosen date
    const HandleDatePick = async(newValue:any) => {        
        HandleBooking("date",new Date(newValue).getTime());
        await GetFreeBookingTimes(new Date(newValue).getTime()) 
    }








    //* Handles Resize  */
    //const [mobileStepper, setMobileStepper] = useState<boolean>(false)
    const mobileStepper = useMediaQuery('(max-width:690px)');
    /* const handleResize = () => {        
        let width =  header_ref.current ? header_ref?.current?.offsetWidth : 0
       
        if(width < 600){
            setMobileStepper(true)
        }else{
            setMobileStepper(false)
        }       
    }
    useEffect(() => {
        handleResize()
    },[])
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

 */


    //* Components
    // Selects which page to display
    const SectionViewer = () => {
        const Viewer = () => {
            switch(page) {
                case 0:
                    return TypeView()                       
                case 1:
                    return PeopleView()                       
                case 2:
                    return DateView()                       
                case 3:
                    return TimeView()                       
                case 4:
                    return FormView()                
                case 5:
                    return SuccessView()                       
                case 6:
                    return ErrorView()          
                case 7:
                    return LoadingView()          
                default:
                    break;
            }
        }
        const steps:any = info?.Reservation?.Form_Steps
        return (
        <Paper className='flex__center-c'  sx={{bgcolor:"primary",padding:"2rem", marginTop:"5rem", width: mobileStepper ? "80%" : "auto"}} ref={paper_ref} >

            {/* Message */}
            <Typography variant='h6' fontSize={"15px !important"} > {message}</Typography>


            {/* Back button */}
            { (page !== 6 && page !== 5) && 
            <Button size="small" onClick={PrevPage} disabled={page === 0} sx={{textAlign:"center"}}>
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
                {info?.Reservation?.Prev_Button}
            </Button>
            }




         
            {/* Progression Stepper */}
            <Box >   

                {/*  Progression is not shown for error or completed reservation */}
                {/*  Turn to mobile width < 600 */}

                { (page !== 6 && page !== 5) && 
             
                <Box>
                    { mobileStepper ? 
                    <Box className="flex__center-c">

                        <Typography variant="h5" color="primary.main">{steps?.[page]}</Typography>

                        <MobileStepper variant="progress"
                            steps={5}
                            position="static"
                            activeStep={page}
                            color="text.secondary"
                            sx={{ width: 400, flexGrow: 1,bgcolor:"#ffffff00" }}
                            nextButton={
                            <Box></Box>
                            }
                            backButton={
                            <Box></Box>
                            }
                        />
                    </Box>
                    : 
                    <Stepper activeStep={page} sx={{marginBottom:"0.5rem"}}>
                       {/*  <Button size="small" onClick={PrevPage} disabled={page === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            {info?.Reservation?.Prev_Button}
                        </Button> */}
                                
                        {steps?.map((label:string, index:number) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};                 
                        return (
                            <Step key={label} {...stepProps} >
                                <StepLabel {...labelProps} /*  sx={{'& .MuiStepLabel-labelContainer':{color:"secondary.main"}}} */>{label}</StepLabel>
                            </Step>
                        );
                        })}
                        
                    </Stepper>
                    }

                </Box>                          
                } 
               
            </Box>      


            {/* Main Content */}      
            <Box className="flex__center-c">
                {Viewer()}                      
            </Box>

        </Paper>
        )
    }
    const TypeView = () => {
        return(
        <Box className="flex__center-c">
            
            <Typography variant='h4'>
               {info?.Reservation?.View_Steps_Type}
            </Typography>

            <Box className="flex__center-r">            
                <Button onClick={()=>{HandleBooking("type","Lunch"); NextPage()}} variant="outlined" sx={{margin:"1rem"}}>{info?.Reservation?.Lunch_Button}</Button>
                <Button onClick={()=>{HandleBooking("type","Middag"); NextPage()}} variant="outlined" sx={{margin:"1rem"}}>{info?.Reservation?.Middag_Button}</Button>
            </Box>

        </Box>
        )
    }
    const PeopleView = () => {
        const maxLengthOfPeople:any = dbInformation?.MaxNumberOfGuestPerReservation
        return (
            <Box className="flex__center-c" > 

                <Typography variant='h4' sx={{m:"0.5rem 0"}}>
                    {info?.Reservation?.View_Steps_Guests}
                </Typography>

                <Box className="flex__center-r" maxWidth={300} sx={{flexWrap:"wrap",gap:"0.5rem"}}> 

                    {Array.from({length:maxLengthOfPeople || 16 } ,(_,i) => (                 
                        <Box className="flex__center-r"  key={`people${i}`}>
                            <Button  onClick={async()=>{HandleBooking("people",i+1); await GetOccupiedBookingDates(i+1);}} variant='outlined' sx={{ borderRadius:"50%", width:"60px",height:"60px"}} >
                                {i+1}
                            </Button>
                        </Box>
                    
                        
                    ))}
                </Box>

            </Box>
        )
    }
    const DateView = () => {        
        return (
            <Box >

                <Typography variant='h4' sx={{m:"0.5rem 0"}}>
                    {info?.Reservation?.View_Steps_Date}
                </Typography>

                <DateCalendar 
                    value={value} 
                    onChange={(newValue) => HandleDatePick(dayjs(newValue,"se").hour(0).minute(0).second(0).millisecond(0))  } 
                    //onClickDay={async(value, event)=>{HandleBooking("date",new Date(value).getTime()); await GetFreeBookingTimes(new Date(value).getTime()) }}  // when a day is clicked save info 
                    disablePast
                    maxDate={dayjs(Date.now()+3600000*24*365/2)} // Can book max 6 months in the future
                    shouldDisableDate={ (day: any) => CheckIfDisabled(day)  } // For disabling some days 
                    
                        
                    sx={{
                        '& .MuiPickersDay-root': {color:"text.secondary",fontWeight:400},                              
                    }}
            
                />
            
            </Box>
        )
    }
    const TimeView = () => {
       
        return (
            <Box className="flex__center-c"> 

                <Typography variant='h4' sx={{m:"0.5rem 0"}}>
                    {info?.Reservation?.View_Steps_Time}
                </Typography>
        
                <Box className="flex__center-r" maxWidth={300} sx={{flexWrap:"wrap",gap:"0.5rem"}}> 

                    {freeTimes?.length > 0 ?
                    freeTimes?.map((time,i)=>{
                        return (
                            <Box className="flex__center-r"  key={`times${i}`} onClick={()=>{HandleBooking("time",time);NextPage()} }>
                                <Button  variant='outlined' sx={{ borderRadius:"50%", minWidth:"50px",height:"50px", fontSize:"13px", padding:0}} >
                                    {new Date(time).toLocaleTimeString("sv-SE", {timeZone: 'Europe/Stockholm', hour: "2-digit", minute: "2-digit" })}
                                </Button>
                            </Box>
                            /* <div className='flex__center-c' key={`times${i}`} onClick={()=>{HandleBooking("time",time);NextPage()} }>
                                <p className='p__font-xsmall'>{new Date(time).toLocaleTimeString("sv-SE", {timeZone: 'Europe/Stockholm', hour: "2-digit", minute: "2-digit" })}</p>
                            </div> */
                        )
                    })
                    :
                    <Typography variant='h5'>
                        {info?.Reservation?.Message_NoTimes}
                    </Typography>
                    }

                </Box>
            </Box>
        )
    }
    const FormView = () => {
        return (
            <Box>

                {/* Final Data (type, people, date) show at the top */}
                <Box className='flex-c'>
                    <Typography className='flex' sx={{color:"text.secondary",  alignItems:"center",gap:"0.2rem",marginBottom:"0.5rem"}}><BrunchDiningIcon color='secondary'/> {info?.Reservation?.Form_Type} {form?.type}</Typography>
                    <Typography className='flex' sx={{color:"text.secondary", alignItems:"center",gap:"0.2rem",marginBottom:"0.5rem"}}><PeopleAltIcon color='secondary'/>{info?.Reservation?.Form_Guests} {form?.people}</Typography>
                    <Typography className='flex' sx={{color:"text.secondary", alignItems:"center",gap:"0.2rem",marginBottom:"0.5rem"}}><DateRangeIcon color='secondary'/>{info?.Reservation?.Form_Date}  {new Date(form?.date).toLocaleDateString()} kl. {new Date(form?.time).toLocaleTimeString("sv-SE", {timeZone: 'Europe/Stockholm', hour: "2-digit", minute: "2-digit" })}</Typography>
                    <p className='p__font-small'></p>                    
                </Box>

                

                {/* Form */}
                <form className='flex__center-c' onSubmit={Submit}>                        
                    

                    {/* Name TextField */}
                    <Box className="flex__center-r" sx={{gap:"0.5rem"}}>
                        <TextField                 
                            error={ error?.firstName ? true : false}
                            helperText={error?.firstName}
                            margin='normal'                    
                            onChange={(e)=>HandleBooking(e.target.name,e.target.value)}
                            value={form?.firstName}
                            required 
                            fullWidth                
                            id="firstName"
                            label={info?.Reservation?.Form_FirstName}
                            name='firstName'                                            
                            autoFocus
                            sx={{'&:hover fieldset': {
                                borderColor: 'grey !important',
                              },}}
                        />

                        {/* Name TextField */}
                        <TextField                 
                            error={ error?.lastName ? true : false}
                            helperText={error?.lastName}
                            margin='normal'                    
                            onChange={(e)=>HandleBooking(e.target.name,e.target.value)}
                            value={form?.lastName}
                            required 
                            fullWidth                
                            id="lastName"
                            label={info?.Reservation?.Form_LastName}
                            name='lastName'                                                                       
                            sx={{color:"text.secondary"}}
                        />
                    </Box>

                    
                    {/* email TextField */}
                    <TextField
                        error={ error?.email ? true : false}
                        helperText={error?.email}
                        margin="normal"
                        onChange={(e)=>HandleBooking(e.target.name,e.target.value)}
                        value={form?.email}
                        required
                        fullWidth
                        id="email"
                        label={info?.Reservation?.Form_Email}
                        name="email"
                        autoComplete="email"
                        type='email'
                        sx={{color:"text.secondary"}}
                    
                    />


                    {/* Phone TextField */}
                    <TextField
                        error={ error?.phone ? true : false}
                        helperText={error?.phone}
                        margin='normal'                    
                        onChange={(e)=>HandleBooking(e.target.name,e.target.value)}
                        value={form?.phone}
                        fullWidth
                        id="phone"
                        label={info?.Reservation?.Form_Phone}
                        name='phone'
                        type='tel'                    
                        autoComplete="tel"
                        sx={{color:"text.secondary"}}
                    
                    />

                    {/* Message TextField */}
                    <TextField
                        margin="normal"
                        onChange={(e)=>HandleBooking(e.target.name,e.target.value)}
                        value={form?.other}
                        fullWidth
                        id="other"
                        label={info?.Reservation?.Form_Message}
                        name="other"                        
                        minRows={3}
                        multiline
                        sx={{color:"text.secondary"}}
                    
                    />

                    {/* Checkbox Accept save of data */}
                    <FormControl
                        required
                        error={error?.approved_storage ? true : false}
                        component="fieldset"
                        sx={{  }}
                        variant="standard"
                    >
                    <FormControlLabel
                        control={<Checkbox  color="secondary" />}
                        label={info?.Reservation?.Checkbox_1}
                        required
                        name='approved_storage'
                        onChange={(e)=>setForm(prev => ({...prev,
                            approved_storage: !form?.approved_storage
                        }))}                       
                        value={form?.approved_storage}
                        sx={{color:"text.secondary"}}
                        //={ error?.phone ? true : false}
                        //helperText={error?.phone}
                    />
                    <FormHelperText>{error?.approved_storage}</FormHelperText>
                    </FormControl>


                    {/* Subscription */}
                    <FormControl                    
                        error={error?.newsletter ? true : false}
                        component="fieldset"
                        sx={{ }}
                        variant="standard"
                    >
                    <FormControlLabel
                        control={<Checkbox  color="secondary" />}
                        label={info?.Reservation?.Checkbox_2}                        
                        name='newsletter'
                        onChange={(e)=>setForm(prev => ({...prev,
                            newsletter: !form?.newsletter
                        }))}                       
                        value={form?.newsletter}
                        sx={{color:"text.secondary"}}
                        //={ error?.phone ? true : false}
                        //helperText={error?.phone}
                    />
                    <FormHelperText>{error?.newsletter}</FormHelperText>
                    </FormControl>


                    {/* Submit Button */}
                    <Button
                        type="submit"
                        
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}                                
                        disabled={loading}                                
                    >
                        {info?.Reservation?.Booking_Button}
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "teal",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>



                    {/* Error message */}
                    {message &&
                    <div className='flex__center-r'>
                    <p className='p__font-small'>{message}</p>
                    </div>
                    }

                    

                </form>   

               

            </Box>
        )
    }
    const SuccessView = () => {
        return(
        <Box className='flex__center-c'>
            {/* <Typography>{info?.Reservation?.Success_Text}</Typography> */}
            <Button variant='contained' onClick={()=>navigate("/")}>{info?.Reservation?.Success_Button}</Button>
        </Box>
        )
    }
    const ErrorView = () => {
        return(
        <Box className='flex__center-c'>
          {/*   <Typography>{info?.Reservation?.Error_Text}</Typography> */}
            <Button variant='contained' onClick={()=>{ !dbInformation?.Reservation_Active ? navigate("/") : window.location.reload()}}>{info?.Reservation?.Prev_Button}</Button>
        </Box>
        )
    }
    const LoadingView = () => {
        return(
        <Box className='flex__center-c'>
            {/*   <Typography>{info?.Reservation?.Error_Text}</Typography> */}
              <CircularProgress color="inherit" />
        </Box>
        )
    }
 
    return (
        <Container className="flex__center-c" maxWidth={false} ref={header_ref} sx={{minHeight:"120vh", position:"relative",padding:"2rem 0", justifyContent:"flex-start"}} >
            {SectionViewer()}       
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}                    
            >
                <CircularProgress color="inherit" />
            </Backdrop>    

            <img src={`${info?.Reservation?.BgImage}`} alt='reservation' className='header-img-noblur'/>
        </Container>
    )
}

export default Reservation