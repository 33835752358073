import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Itakeaway } from '../types/interface'





const serverAddress = process.env.NODE_ENV === "production" ? `https://api.${process.env.REACT_APP_COMPONENT}.se` : "http://localhost:5000"


export const PaymentReqSwish = createAsyncThunk(
    "post/PaymentReqSwish",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.post(`${serverAddress}/paymentreqswish`,info)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})

export const PaymentReqStripe = createAsyncThunk(
    "post/PaymentReqStripe",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.post(`${serverAddress}/paymentreqstripe`,info)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})
export const GetQrSwish = createAsyncThunk(
    "get/GetQrSwish",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getqrswish/?token=${info?.token}`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})
export const GetSwishStatus = createAsyncThunk(
    "get/GetSwishStatus",
    async(info: any,{ getState } ) => {
        try {                           
            const res = await axios.get(`${serverAddress}/getswishstatus/?id=${info.id}`);
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})

export const GetShop = createAsyncThunk(
    "get/GetShop",
    async(info: any,{ getState } ) => {                
    try {
   
        const res = await axios.get(`${serverAddress}/getshop/?typeofmenu=${info.typeOfMenu}`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})
export const GetLeftover = createAsyncThunk(
        "get/GetLeftover",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getshop/?typeofmenu=${info.typeOfMenu}`)        
        return res.data
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})

interface Iprops {
    items: Itakeaway[]
    categories: string []    
}

interface Istate {
    status: string
    takeaway: Iprops 
    leftover: Iprops
}
const initalStateValue:Istate = {
    status: "inital",
    takeaway: {
        items: [],
        categories: [],
    },
    leftover: {
        items: [],
        categories: [],
    },
    
}


const postSlice = createSlice({
    name:"shop",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {       
        builder.addCase(PaymentReqSwish.fulfilled,(state, action) => {
           
            
        })
        builder.addCase(GetShop.fulfilled,(state, action) => {
         
            if(action?.payload?.statusCode === 200){
                const foundItems = action.payload?.body?.data.filter((item:any) => item.sk === "takeaway" && item.pk !== "category" )
                const foundCategory = action.payload?.body?.data.filter((item:any) => item.sk === "takeaway" && item.pk === "category" )
             
                state.takeaway.items = foundItems
                state.takeaway.categories = foundCategory[0].categories
                
            }
            
        })
        builder.addCase(GetLeftover.fulfilled,(state, action) => {
            
            if(action?.payload?.statusCode === 200){
                const foundItems = action.payload?.body?.data.filter((item:any) => item.sk === "leftover" && item.pk !== "category" )
                const foundCategory = action.payload?.body?.data.filter((item:any) => item.sk === "leftover" && item.pk === "category" )
               
                state.leftover.items = foundItems
                state.leftover.categories = foundCategory[0].categories

                
                
            }
            
        })
      
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
    }
})

const { reducer } = postSlice

export default reducer

 