import { useRef } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Mui
import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';

import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';

import { Iinfo, IeditInfo } from '../../types/interface'
gsap.registerPlugin(ScrollTrigger)

const NavbarStyle = {
    //position: "fixed",
    //bottom: 0, left:0,right:0,
    //margin:"0 auto",
    minHeight: "25rem",   
    //zIndex: 5,   
    '& .MuiBreadcrumbs-separator':{
        color: "primary.main"
    
    }
   

}






interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
}
const IconStyle = {
    width:"0.8rem",height:"0.8rem", mr:"0.2rem"
}
const Footer = (props:Iprops) => {
    const { info, editInfo } = props

    const header_ref = useRef(null)

   

    return (
        <Box  component="footer" ref={header_ref} sx={NavbarStyle} className="flex__center-c" color="text.secondary" id="footer">

            <Box className="flex__center-c">
                                        
                <Typography variant='h2' color="primary.dark" mb="0.2rem">{info?.Footer?.Title_1}</Typography>
                
                <Box className="flex__center-r">
                    <AlternateEmailIcon sx={IconStyle} color='secondary'/>
                    <Typography variant='h5' /* color="text.primary" */>{editInfo?.Footer?.Address}</Typography>
                </Box>
                <Box className="flex__center-r" m="0.4rem 0">
                    <PhoneIcon sx={IconStyle} color='secondary'/>
                    <Typography variant='h5' /* color="text.primary" */>{editInfo?.Footer?.Email}</Typography>
                </Box>
                <Box className="flex__center-r" mb="1rem">
                    <LocationOnIcon sx={IconStyle} color='secondary'/>
                    <Typography variant='h5'  /* color="text.primary" */>{editInfo?.Footer?.Phone}</Typography>
                </Box>
                
                
                <Typography variant='h5' color="secondary.main">{info?.Footer?.OpenTimes}</Typography>
                <Box className="flex__center-r" sx={{width:"100%",gap:"2rem"}}>
                    <Typography variant='h5' /* color="text.primary" */>{editInfo?.Footer?.Time_Dinner}</Typography>                
                </Box>                

                <Typography variant='h5' color="secondary.main">{info?.Footer?.Lunch}</Typography>
                <Box className="flex__center-r" sx={{width:"100%",gap:"2rem"}}>
                
                    <Typography variant='h5' /* color="text.primary" */>{editInfo?.Footer?.Time_Lunch}</Typography>
                </Box>
                                                
                <Box className="flex__center-r" sx={{mt:"1rem"}}>
                    
                    <IconButton sx={{}} href={ editInfo?.Footer?.Instagram_Link || "" } target="_blank" rel="noopener">
                        <InstagramIcon fontSize='large' color='primary' />
                    </IconButton>

                    <IconButton sx={{}} href={editInfo?.Footer?.Facebook_Link || "" } target="_blank" rel="noopener">
                        <FacebookIcon fontSize='large' color='primary' />
                    </IconButton>

                    
                </Box>

                <Typography variant='h6' /* color="text.primary" */>{info?.Footer?.Designed_By}</Typography>

            </Box>
            
        </Box> 
    )
}

export default Footer