import { useState, useEffect, useRef} from 'react'

import Container from '@mui/material/Container'

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';




// REDUX
import { GetGallery } from '../../slices/fetchReducer'; 
import  store  from '../../store'
import { useDispatch } from 'react-redux'

import { Iinfo } from '../../types/interface'
import { BackDropIntro } from '../../components'


interface IMasonryImageList {
    galleryList: string []
}

function srcset(image: string, size: number, rows = 1, cols = 1) {
 
    return {
     // src: `https://${process.env.REACT_APP_CDN_URL}/${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
     src: `https://${process.env.REACT_APP_CDN_URL}/${image}`,
      /* srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`, */
    };
}

  

  
const QuiltedImageList = (props:IMasonryImageList) => {
    const { galleryList } = props

    const imagelist_ref:any = useRef(null)

    const [numberOfColumns, setNumberOfColumns] = useState<number>(4)
    const handleResize = () => {
        let width =  imagelist_ref.current ? imagelist_ref?.current?.offsetWidth : 0
       
        if(width < 600){
            setNumberOfColumns(2)
        }else{
            setNumberOfColumns(4)
        }
    }
    useEffect(() => {          
        handleResize()    
        window.addEventListener("resize", handleResize, false);
    }, [])
    
    
    return (
      <ImageList
        sx={{ width: "100vw",m:0 }}
        variant="quilted"
        cols={numberOfColumns}
        gap={0}
        ref={imagelist_ref}
        
      >
        {galleryList.map((item,i) => {

            let rows, columns;

            if (i % 5 === 0 || i % 5 === 5) {
                rows = 2;
                columns = 2;
            } else if (i % 5 === 1 || i % 5 === 2 || i % 5 === 7 || i % 5 === 8) {
                rows = 1;
                columns = 1;
            } else {
                rows = 1;
                columns = 2;
            }
        //let img = `https://${process.env.REACT_APP_CDN_URL}/${item}`
        let img = `${item}`
        return(
          <ImageListItem key={`${img}${i}`} cols={columns || 1} rows={rows || 1}>
            <img
              {...srcset(img, 121, rows, columns)}
              alt={"test"}
              loading="lazy"
            />
          </ImageListItem>
        )})}
      </ImageList>
    );
}

interface Iprops {
    info: Iinfo | undefined
}
const Gallery = (props:Iprops) => {
    const { info } = props
    type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
      

    const header_ref = useRef(null)



    //* Get Gallery Json */
    const [galleryList, setGalleryList] = useState<string []>([])
    useEffect(() => {
        dispatch(GetGallery({}))
            .unwrap()
            .then( res => {                
                setGalleryList(res?.Gallery)
            })

    
      return () => {
        
      }
    }, [])
    

    //* Scroll to top  */
    useEffect(() => {      
        window.scrollTo(0, 0);
    },[])




    //* Backdrop, shows Title at start of page  */
/*     const [open, setOpen] = useState(true);
    const handleClose = () => {
      setOpen(false);
    };        
    useEffect(() => {                 
        const onScroll = () => {
            if(window.scrollY === 0){
                setOpen(true);
              }else{
                setOpen(false);
              }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [window.scrollY])



 */

    return (
        <Container className="flex__center-c" maxWidth={false} ref={header_ref}  sx={{justifyContent:"flex-start",minHeight:"100vh", position:"relative", bgcolor:"", marginTop:"0 !important"}} >

            {/* Image list */}
            {/* <MasonryImageList galleryList={galleryList || []} /> */}
            <QuiltedImageList galleryList={galleryList || []} />


            {/* Intro Backdrop title */}         
            <BackDropIntro title={info?.Gallery?.Backdrop_Title}/>


        </Container>
    )
}

export default Gallery