import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { useNavigate, useSearchParams } from 'react-router-dom'


import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Iinfo } from '../../types/interface';

// redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { clearCart } from '../../slices/cartReducer';

interface Iprops {
    info: Iinfo

}
const SuccessPayment = (props:Iprops) => {
    const { info } = props

    const navigate = useNavigate()
    const location = useLocation()

    const dispatch: AppDispatch = useDispatch()
    
    const [searchParams] = useSearchParams();
    
    
    const redirect_status = searchParams.get("success")
    

    const infoToUse = redirect_status === "true" ? info?.SuccessPayment_Success : info?.SuccessPayment_Error
    
    const handleNavigate = () => {
        if(redirect_status === "true"){
            navigate("/")
        }else{
            navigate("/checkout")
        }
        
    }

    useEffect(() => {
        if(redirect_status === "true"){
            dispatch( clearCart("") )
        }
    
        return () => {
            
        }
    })
    

    return (
        <Container className="flex__center-c" maxWidth={false} sx={{minHeight:"100vh", position:"relative", bgcolor:"primary.main"}} >


            <Paper
                elevation={10}
                sx={{
                    bgcolor:"primary.light", 
                    p:"2rem",
                    //boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
                    }} className='flex__center-c' 
            >
                <Typography variant='h3'>
                    {infoToUse?.Title}
                </Typography>

                <Typography variant='subtitle1' maxWidth={`90%`}>
                    { location?.state?.message ? location?.state?.message : infoToUse?.Message}
                </Typography>


                {redirect_status === "true" ? 
                <CheckCircleOutlineIcon sx={{width:"10vmax",height:"10vmax", m:"2rem 0"}} color='success' />
                :
                <HighlightOffIcon sx={{width:"10vmax",height:"10vmax", m:"2rem 0"}} color='error'/>
                }


                <Button variant='contained' color={redirect_status === "true" ? 'success' : 'error'} onClick={handleNavigate }>
                    {infoToUse?.Button}
                </Button>
                

            </Paper>
            
        </Container>
    )
}

export default SuccessPayment