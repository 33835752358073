import { useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery';

// self
import { Iinfo } from '../../types/interface'
import { MainLoading, Logo } from '../../components'
import { theme } from '../../App'



interface Iprops {
    info: Iinfo | undefined
}
const About = (props:Iprops) => {
    const { info } = props

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const windowSize = useMediaQuery('(max-width:690px)');  
    
    const headerStyle = {
        column: {
            display:"flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight:"100vh",
            mt:"10rem"
     
        },
        row: {
            display:"flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            minHeight:"100vh",
            
        }
    }
    const ImageBoxStyle = {
        column: {
            width: "100vw",
            height:"100%",
            position: "relative",
            mt:"5rem",
            boxShadow:"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            
        },
        row: {
            width: "50vw",
            boxShadow:"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            position: "relative",
           

        }
    }
    const TextBoxStyle = {
        column: {
            width: "100vw",
      
            position: "relative",
            
        },
        row: {
            width: "50vw",
          
            position: "relative",
          

        }
    }

    //* Window scroll   */
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {            
        }
    }, [])

    return (
        <Box component="section" className="flex__center-r"  sx={ windowSize ?  headerStyle.column :  headerStyle.row } >

            {/* Text */}
            <Box className="flex__center-c" sx={ windowSize ?  TextBoxStyle.column :  TextBoxStyle.row }>
                <Box className="flex__center-c" width="90%">
                
                    <Typography variant="h4">
                        {info?.About?.SubTitle}
                    </Typography>
                    <Typography variant="h2" m="0 0 2rem 0" color="primary">
                        {info?.About?.Title}
                    </Typography>

                    <Typography variant="subtitle1" m="2rem 0">
                        {info?.About?.Intro1}
                    </Typography>
                    <Logo height={3} width={3} fill={ theme.palette.primary.main } navigateOn={false} />

                    <Typography variant="subtitle1" m="2rem 0">
                        {info?.About?.Intro2}
                    </Typography>
                    <Logo height={3} width={3} fill={ theme.palette.primary.main } navigateOn={false} />

                    <Typography variant="subtitle1" m="2rem 0">
                        {info?.About?.Intro3}
                    </Typography>
                </Box>
                

                <Button variant='outlined' size="large" color="primary" onClick={()=>navigate("/reservation")}>
                    {info?.About?.Button_Reservation}
                </Button>
            </Box>

            {/* image */}
            <Box className="flex__center-c" sx={ windowSize ?  ImageBoxStyle.column :  ImageBoxStyle.row }>
                <img className="menu-img-container" src={info?.About?.Image} alt="header" onLoad={()=> setLoading(false) } onError={()=> setLoading(false)  } />   
            </Box>



            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info} />
            }

        </Box>
    )
}

export default About