import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';



export default function Review(formData:any,shopitems:any,paymentMethod:string,props:any,setFormData:any) {

   const { info } = props
    
  return (
    <React.Fragment >
        <Typography variant="h6" gutterBottom>
            {info?.Review?.OrderSummary}
        </Typography>
        <List disablePadding>
            {shopitems?.cart?.map((product:any,i:number) => (
                <ListItem key={`reviewlistitem${product.title}${i}`} sx={{ py: 1, px: 0 }}>
                    <ListItemText primary={product.title} secondary={`st. ${product.quantity} `} />
                    <Typography variant="body2">{(product.price * product.quantity).toFixed(2)} kr</Typography>
                </ListItem>
                
            ))}
      {/*       <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary="Shipping" />
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    FREE
                </Typography>
            </ListItem> */}
            <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary="Total" />
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                    {info?.Review?.Currency_SEK} {shopitems?.totalprice?.toFixed(2)}
                </Typography>
            </ListItem>
        </List>
        <Grid container spacing={2} >
            <Grid item xs={12} sm={6}  sx={{}}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2, textAlign:"left" }}>
                {info?.Review?.CustomerInfo}
            </Typography>
            {/* <Typography gutterBottom>John Smith</Typography> */}
            {Object?.keys(formData)?.map( (key,i) => {
                if(key === "approveNewsletter" || key === "store_data"){
                    return
                }
                return(
                    <Typography variant='subtitle1' key={`addressinfo${key}${i}`} sx={{textAlign:"left"}}>{formData?.[key]}</Typography>
                )
            })}
            </Grid>
   {/*          <Grid item container direction="column" xs={12} sm={6}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 ,textAlign:"left"}}>
                    Payment details
                </Typography>
                <Grid container>            
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' gutterBottom sx={{textAlign:"left"}}>Payment Method</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' gutterBottom sx={{textAlign:"left"}}>{paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}</Typography>
                    </Grid>            
                </Grid>
            </Grid> */}

            

        </Grid>

        {/* Store data and newsletter checkbox */}
        <Box className="flex__center-c" alignItems="flex-start">
            {/* Checkbox Accept newsletter */}
            <FormControl                       
                component="fieldset"
                sx={{ m: "2rem 0 0 0" }}
                variant="standard"
            >
                <FormControlLabel
                    control={<Checkbox   color="secondary" />}
                    label={info?.Checkout?.AcceptNewsletter_Checkbox}                                            
                    name='approveNewsletter'
                    onChange={(e)=>setFormData((prev:any) => ({...prev,
                        approveNewsletter: !formData?.approveNewsletter
                    }))}                       
                    value={formData?.approveNewsletter}
                    //={ error?.phone ? true : false}
                    //helperText={error?.phone}
                />                    
            </FormControl>

            {/* Checkbox storing of data */}
            <FormControl                       
                    component="fieldset"
                    required
                    variant="standard"
                >
                <FormControlLabel
                    control={<Checkbox required  color="secondary" />}
                    label={info?.Checkout?.Store_Data_Checkbox}                                            
                    name='store_data'
                    onChange={(e)=>setFormData((prev:any) => ({...prev,
                        store_data: !formData?.store_data
                    }))}                       
                    value={formData?.store_data}
                    //={ error?.phone ? true : false}
                    //helperText={error?.phone}
                />                    
            </FormControl>
        </Box>

    </React.Fragment>
  );
}