import { GetReservationDates } from '../slices/reservationReducer'


import store from '../store'

export const FetchReservedDates = async(people:number, type:string, dateNow:number) => {
    try {
            
        let hasDataBeenFetched = store.getState()?.reservationReducer?.fetch
      
        let ReservedDates = store.getState()?.reservationReducer?.times
      
        
        if(!hasDataBeenFetched){
            await store.dispatch(GetReservationDates({people: people, type: type, dateNow: dateNow}))
                .unwrap()
                .then((res) => {            
                    //ReservedDates = store.getState()?.reservationReducer?.times        
                    ReservedDates = res?.body?.data.map((item: {sk:number, type:string }) => item?.sk)
                })
                .catch((error) => {
                    console.log(error)                                        
                    return {statusCode: 500, data: []}   
                })
        }
        return {statusCode: 200, data: ReservedDates}   

    } catch (error) {
        console.log("bookingfunc error",error)              
        return {statusCode: 500, data: []}   
    }

}

