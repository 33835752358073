import { useEffect, useState } from 'react';


import { MenuList } from '../../components'

import { Iinfo, IeditInfo } from '../../types/interface';
import { ReStructureMenu } from '../../functions/ReStructureMenu';

interface fetchmenuinit {    
    title: string
    info: string        
    price: number
    category: string
}
interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
    menuprops: fetchmenuinit[]
    menucategoryprops: string []
}

const Menu = (props:Iprops) => {
    const { info, editInfo, menuprops, menucategoryprops } = props
    
    const [menu, setMenu] = useState(ReStructureMenu(menuprops,menucategoryprops))
    const [menuLoading, setMenuLoading] = useState<boolean>(true)
    
    //* Rerender props */
    useEffect(() => {
        
        setMenu(ReStructureMenu(menuprops,menucategoryprops))    
        setMenuLoading(false)
        return () => {
            
        }
    }, [menuprops])

    //* Window scroll   */
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            
        }
    }, [])
    
    
   

    

    return (
        <MenuList menu={menu} info={info?.Menu} editInfo={editInfo?.Menu} type='menu' menuLoading={menuLoading} />
    )
}

export default Menu