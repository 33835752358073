import { useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@mui/material/Typography'



interface Iprops {
    title: string | undefined
}
const BackDropIntro = (props:Iprops) => {
    const { title } = props

    //* Backdrop, shows Title at start of page  */
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };  
    useEffect(() => {                 
        const onScroll = () => {
            if(window.scrollY === 0){
                setOpen(true);
              }else{
                setOpen(false);
              }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [window.scrollY])


    return (
        
        <Backdrop
            sx={{cursor:"pointer", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        
            >                
            <Typography component="h1" variant='h1' color="primary.contrastText" paragraph sx={{fontWeight:900}}>
                {title}
            </Typography>
        </Backdrop>
    )
}

export default BackDropIntro