import { Itakeaway,Itakeawayreturnitem } from "../types/interface"
interface Ifetchmenu {
    title: string
    info: string        
    price: number
    category: string
}

export const ReStructureMenu = (menuArr: Ifetchmenu[], menuCategories: string []) => {    
     
    const returnObj:any = {}
    menuCategories?.forEach((item) => returnObj[item] = [] )
        
    for (let i = 0; i < menuArr?.length; i++) {
        const menuitem = menuArr[i];
        //console.log("menuitem?.category",menuitem?.category)
        returnObj[menuitem?.category]?.push({
            title: menuitem?.title,
            price: menuitem?.price,
            info: menuitem?.info,
        })
    }
   
    return returnObj
}


interface Itakeawayreturn {
    [key:string] : Itakeawayreturnitem []
}
export const ReStructureTakeaway = (menuArr: Itakeaway[], menuCategories: string []) => {    
     
    const returnObj:Itakeawayreturn = {} as Itakeawayreturn
    menuCategories?.forEach((item) => returnObj[item] = [] )
        
    for (let i = 0; i < menuArr?.length; i++) {
        const menuitem = menuArr[i];
     
        if(menuitem?.quantity > 0){
            returnObj[menuitem?.category]?.push({
                title: menuitem?.title,
                price: menuitem?.price,
                info: menuitem?.info,
                active: menuitem?.active,
                pk: menuitem?.pk,
                sk: menuitem?.sk,
            })
        }
        
    }

    return returnObj
}


export const ReStructureLeftover = (menuArr: Itakeaway[], menuCategories: string []) => {    
     
    const returnObj:Itakeawayreturn = {} as Itakeawayreturn
    menuCategories?.forEach((item) => returnObj[item] = [] )
        
    for (let i = 0; i < menuArr?.length; i++) {
        const menuitem = menuArr[i];
        if(menuitem?.quantity > 0){
            returnObj[menuitem?.category]?.push({
                title: menuitem?.title,
                price: menuitem?.price,
                oldPrice: menuitem?.oldPrice,
                info: menuitem?.info,
                active: menuitem?.active,
                pk: menuitem?.pk,
                sk: menuitem?.sk,
            })                         
        }                               
    }

    return returnObj
}