import { useEffect, useState } from 'react';

// mui


// self
import {  MenuList } from '../../components'
import { Iinfo, IeditInfo, Itakeawayreturnitem } from '../../types/interface';

import { ReStructureTakeaway } from '../../functions/ReStructureMenu';
import { FetchInfoFromStore } from '../../functions/Info';
import { FetchTakeawayFromStore } from '../../functions/Shop'; 




interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
   
}

const Takeaway = (props:Iprops) => {
    const { info, editInfo } = props
 
    interface Imenu {
        [key:string]: Itakeawayreturnitem []

    }
    const [menu, setMenu] = useState<Imenu>({})      
    const [active, setActive] = useState<boolean | undefined>(false) 
    const [menuLoading, setMenuLoading] = useState<boolean>(true)

    
    //* Rerender props */
    useEffect(() => {

        const asyncfunc = async() => {
            const infores = await FetchInfoFromStore()
            setActive(infores?.data?.Shop_Active) 
            const res = await FetchTakeawayFromStore()        
            
            const restake = ReStructureTakeaway(res?.data?.items, res?.data?.categories)
            
            setMenuLoading(false)
            setMenu(restake)                       
        }
        asyncfunc()
        


          
        return () => {
            
        }
    }, [])

    //* Window scroll   */
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            
        }
    }, [])


    

    return (              
       <MenuList menu={menu} info={info?.Takeaway} editInfo={editInfo?.Takeaway} type='takeaway' active={active} menuLoading={menuLoading}/>
    )
}

export default Takeaway