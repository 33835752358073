import { useEffect, useState } from 'react';

// self
import {  MenuList } from '../../components'
import { Iinfo, IeditInfo , Itakeawayreturnitem} from '../../types/interface';


import { ReStructureLeftover } from '../../functions/ReStructureMenu';
import { FetchInfoFromStore } from '../../functions/Info';
import { FetchLeftoverFromStore } from '../../functions/Shop'; 


interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
   
}

const Leftover = (props:Iprops) => {
    const { info, editInfo } = props

    
    interface Imenu {
        [key:string]: Itakeawayreturnitem []

    }
    const [menu, setMenu] = useState<Imenu>({})
 
   /*  const [allLoading, setAllLoading] = useState({
        image: true,
        menu: true
    }) */
    const [active, setActive] = useState<boolean | undefined>(false) 
    const [menuLoading, setMenuLoading] = useState<boolean>(true)
    
    //* Rerender props fetch menu*/
    useEffect(() => {

        const asyncfunc = async() => {
            const infores = await FetchInfoFromStore()
            setActive(infores?.data?.Shop_Active) 
            const res = await FetchLeftoverFromStore()        
            
            setMenuLoading(false)
            setMenu(ReStructureLeftover(res?.data?.items, res?.data?.categories))               
         /*    setAllLoading(prev => ({...prev, menu: false})) */
        }
        asyncfunc()
        


          
        return () => {
            
        }
    }, [])

    //* Window scroll   */
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            
        }
    }, [])


    // check Loading from takeaway and image
  /*   useEffect(() => {
        
        if(!allLoading?.image && !allLoading?.menu){
            setLoading(false)
        }
    
      return () => {
        
      }
    }, [allLoading]) */
    
    

  
   
   

    
    
 
   
    

    return (        
        <MenuList menu={menu} info={info?.Leftover} editInfo={editInfo?.Leftover} type='leftover' active={active} menuLoading={menuLoading} />
    )
}

export default Leftover